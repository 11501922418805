import {Link} from '@/interfaces/Link';
import {BaseModel} from '@/models/BaseModel';
import {EagerLoadOption} from '@/models/BaseModelInterface';
import FairCategory from '@/models/FairCategory';
import FairProfile from '@/models/FairProfile';
import moment from 'moment';


export interface IProgram {
    imageUrl: null | string;
    text: null | string;
    startDate: null | moment.Moment;
    endDate: null | moment.Moment;
    link: null | Link;
}

interface IProgramRaw extends Omit<IProgram, 'startDate' | 'endDate'> {
    startDate: string | null;
    endDate: string | null;
}

export default class Fair extends BaseModel {

    /**
     * The name of the store Module
     *
     * @type {null|string}
     * @private
     */
    static _entity = 'fairs';

    /**
     * the logo url
     */
    public logoUrl: string | null = null;
    public backgroundImageUrl: string | null = null;
    public fairContentImage: string | null = null;
    /**
     * The text of the fair
     */
    public text: string | null = null;
    /**
     * Video information
     */
    public video: string | null = null;

    /**
     * The color hex value
     */
    public colorHex: string | null = null;

    /**
     * date when the fair starts
     */
    public startDate: moment.Moment | null = null;
    /**
     * Date when the fair ends
     */
    public endDate: moment.Moment | null = null;
    /**
     * Date when the fair cannot be seen anymore
     */
    public publishEndDate: moment.Moment | null = null;

    public hasChat: boolean = false;
    public hasVideo: boolean = false;
    public linkToThaff: boolean = false;
    public showProfilesInPreview: boolean = false;

    /**
     * An array of category ids for this fair
     */
    public categoryIds: number[] = [];
    /**
     * the ids of all fair profiles for this fair
     */
    public fairProfileIds: number[] = [];

    public svgWall: string = '';
    public svgStand: string = '';
    public svgFloor: string = '';
    public standBackground: string = '';
    news: {
        id: number,
        headline: string | null,
        imageUrl: string | null,
        text: string | null,
        dateFormatted: string | null,
        link: Link
    }[] = [];

    fairLinks: Link[] = [];

    sponsors: {
        id: number,
        title: string | null,
        phone: string | null,
        imageUrl: string | null,
        text: string | null,
        link: Link,
        isPremium: boolean
    }[] = [];

    public hasFeedbackForm: boolean = false;
    public feedbackHeadline: string | null = null;
    public feedbackText: string | null = null;
    public feedbackLink: Link | null = null;

    public feedbackTimeout: number | null = null;
    public mouseTimeout: number | null = null;

    private _programs: IProgram[] = [];

    public imageWall: string|null = null;
    public imageFloor: string|null = null;

    constructor(...args: any[]) {
        super(...args);
        this.init(...args);
    }

    init(...args: any[]) {
        super.init(...args);
    }

    getDateAttributes() {
        let attributes = super.getDateAttributes();
        attributes.push('startDate');
        attributes.push('endDate');
        attributes.push('publishEndDate');

        return attributes;
    }

    /**
     * Eager load map for this element
     *
     * @return {{attribute: string, entity: string}[]}
     */
    static eagerLoadAttributes(): EagerLoadOption[] {
        return [
            {attribute: 'categories', entity: FairCategory.getEntity()},
            {attribute: 'fairProfiles', entity: FairProfile.getEntity()},
        ];
    }


    /**
     * Get the fair profiles for this fair
     *
     * @return {FairProfile[]}
     */
    get fairProfiles() {
        return this.getMany({
            attribute: '_fairProfiles',
            entity: FairProfile,
            ids: this.fairProfileIds
        });
    }

    /**
     * Set the fair profiles for this fair
     *
     * @param {FairProfile[]} models
     */
    set fairProfiles(models) {
        this.setMany({
            attribute: '_fairProfiles',
            entity: FairProfile,
            models
        });
    }


    /**
     * Get the fair categories for this fair
     *
     * @return {FairCategory[]}
     */
    get categories() {
        return this.getMany({
            attribute: '_fairCategories',
            entity: FairCategory,
            ids: this.categoryIds
        });
    }

    /**
     * Set the fair categories for this fair
     *
     * @param {FairCategory[]} models
     */
    set categories(models) {
        this.setMany({
            attribute: '_fairCategories',
            entity: FairCategory,
            models
        });
    }

    /**
     * get all programs
     */
    get programs(): IProgram[] {
        return this._programs;
    }

    /**
     * Set all programs
     *
     * @param programs
     */
    set programs(programs: IProgram[]) {
        this._programs = programs.map(el => {
            if (moment.isMoment(el.startDate)) {
                return el;
            }

            const newEl: IProgram = {...el};
            newEl.startDate = this.setTimeValue('startDate', newEl.startDate);
            newEl.endDate = this.setTimeValue('startDate', newEl.endDate);

            return newEl;
        });
    }
}
