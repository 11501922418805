import {hasOwnProperty} from "@/services/Utils";

export const parseParameters = (params: { [name: string]: any }) => {
    let url = '';

    if (typeof params.with !== "undefined") {
        url += 'include=' + params.with.join() + '&';
    }


    if (typeof params['page'] !== "undefined") {
        url += 'page=' + params.page + '&';
    }


    if (typeof params['per-page'] !== "undefined") {
        url += 'page=' + params['per-page'] + '&';
    }

    return url;
};

/**
 *
 * @param obj
 * @param prefix
 */
export const serialize = (obj: any, prefix?: string | null): any => {
    const str = [];
    let p;
    for (p in obj) {
        if (hasOwnProperty(obj, p)) {
            const k = prefix ? prefix + "[" + p + "]" : p;
            let v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}