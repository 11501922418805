import Socket from '@/api/socket/Socket';
import FairProfile from '@/models/FairProfile';

let client: null | Socket = null;
export const joinRooms = (token: string) => {
    const c = getClient(token);
    if (!c.connected) {

        c.reconnect(token);
        c.joinRooms(token);
    }
};


export const joinCompany = (profile: FairProfile) => {
    const c = getClient();

    c.joinCompanyRoom(profile.uid);
};

export const leaveCompany = (profile: FairProfile) => {
    const c = getClient();

    c.leaveCompanyRoom(profile.uid);
};

export const requestChat = (profile: FairProfile, username: string) => {
    const c = getClient();

    c.requestChat(profile.uid, username);
};

export const requestVideo = (profile: FairProfile, username: string) => {
    const c = getClient();

    c.requestVideo(profile.uid, username);
};

export const sendChatMessage = (companyId: string, userId: string, messsage: string) => {
    const c = getClient();

    c.sendChatMessage(companyId, userId, messsage);
};

export const sendChatEnd = (companyId: string, userId: string) => {
    const c = getClient();

    c.sendChatEnd(companyId, userId);
};

export const sendChatRead = (companyId: string, userId: string) => {
    const c = getClient();

    c.sendChatRead(companyId, userId);
};

export const videoConnected = (companyId: string, userId: string) => {
    const c = getClient();

    c.videoConnected(companyId, userId);
};

export const sendVideoEnd = (companyId: string, userId: string) => {
    const c = getClient();

    c.sendVideoEnd(companyId, userId);
};


export type TrackFairEventType = 'users' | 'sessions' | 'page-views' | 'click' | 'tab';
export type TrackFairProfileEventType = 'tab' | 'request-chat' | 'request-video' | 'social' | 'link' |
    'social-facebook' | 'social-youtube' | 'social-instagram' | 'social-twitter' | 'social-linkedIn';

/**
 * Track fair events
 *
 * @param fairId
 * @param profileId
 * @param event
 * @param suffix
 */
export const trackFairEvent = (fairId: number, profileId: number | null, event: TrackFairEventType | TrackFairProfileEventType, suffix?: string) => {
    getClient().trackFairEvent(fairId, profileId, event, suffix);
};


/**
 * Leave all rooms -> disconnect
 */
export const disconnect = () => {
    if (client !== null && client.connected) {
        client.token = null;
        if (client.socket !== null) {
            client.socket.disconnect();
        }
    }
};

export const getClient = (token?: string) => {
    if (client === null) {
        client = new Socket(token);
    } else if (client && !client.connected) {
        // set new token and connect
        if(client.connect(token)){
            client.sendCommandQueue();
        }
    }


    return client;
};
