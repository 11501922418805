import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

const prefix = process.env.BASE_URL;
const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/:slug',
        name: 'fair',
        component: () => import('@/views/Fair.vue'),
        children: [
            {
                path: '/:slug/info/:tab?',
                name: 'fairInfo',
                component: () => import('@/views/FairInfo.vue'),
            },
            {
                path: '/:slug/profil/:profile/:tab?',
                name: 'fairProfile',
                component: () => import('@/views/FairProfile.vue'),
            },
            {
                path: '/:slug/vorschau/:profile/:tab?',
                name: 'fairProfilePreview',
                component: () => import('@/views/FairProfile.vue'),
            },
            {
                path: '/:slug/video/:roomUrl',
                name: 'fairVideoChat',
                component: () => import('@/views/FairVideoChat.vue'),
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/ErrorNotFound.vue'),
    }
];

export const router = createRouter({
    history: createWebHistory(prefix),
    routes,
});
