import {BaseModel} from "@/models/BaseModel";
// @ts-ignore
import {clone} from 'lodash'
import {BaseModelInterface} from "@/models/BaseModelInterface";
import moment from "moment";

/**
 * Compare 2 Objects, return the delta
 *
 * @param newObj
 * @param oldObj
 */
export const compareObjects = (newObj: BaseModelInterface, oldObj: BaseModelInterface | null = null): BaseModelInterface | boolean | { [key: string]: any }  => {
    let hasDifferences: boolean = false

    if (!oldObj) {
        return newObj
    }

    let result: BaseModel | { [key: string]: any } = newObj instanceof BaseModel ? Object.create(Object.getPrototypeOf(newObj)) : {};

    for (let prop in newObj) {
        if (!Object.prototype.hasOwnProperty.call(newObj, prop)) {
            continue;
        }

        if (!Object.prototype.hasOwnProperty.call(oldObj, prop)) {
            result[prop] = newObj[prop]
            hasDifferences = true
            continue;
        }

        if (Array.isArray(newObj[prop])) {
            if (!Array.isArray(oldObj[prop]) || newObj[prop].length !== oldObj[prop].length) {
                result[prop] = clone(newObj[prop])
                hasDifferences = true
                continue;
            }
            for (let i = 0; i < newObj[prop].length; i++) {
                if (typeof newObj[prop][i] === 'object' && newObj[prop][i] !== null) {
                    let tempResult = compareObjects(newObj[prop][i], oldObj[prop][i])
                    if (tempResult) {
                        result[prop] = clone(newObj[prop])
                        hasDifferences = true
                        break;
                    }
                } else if (!oldObj[prop].includes(newObj[prop][i])) {
                    result[prop] = clone(newObj[prop])
                    hasDifferences = true
                    break;
                }
            }
        } else if (typeof newObj[prop] === 'object' && newObj[prop] !== null) {
            if(moment.isMoment(newObj[prop]) === false){
                let tempResult = compareObjects(newObj[prop], oldObj[prop])
                if (tempResult) {
                    result[prop] = tempResult
                    hasDifferences = true
                }
            }else if(!newObj[prop].isSame(oldObj[prop])){
                result[prop] = newObj[prop].clone();
                hasDifferences = true
            }
        } else if (newObj[prop] !== oldObj[prop]) {
            result[prop] = newObj[prop]
            hasDifferences = true
        }
    }

    if(newObj instanceof BaseModel){
        newObj.getBaseAttributes().forEach(attribute => {
            result[attribute] = newObj[attribute];
        });

        newObj.getInternalAttributes().forEach(attribute => {
            delete(result[attribute]);
        });
    }

    return hasDifferences ? result : false
}