
import {router} from "@/router/router";
import {store} from "@/store/store";
import {createApp} from 'vue'
import App from '@/App.vue'

const app = createApp(App)
// addDevtools(app, store)
import moment from 'moment-timezone';
moment.tz.setDefault("Europe/Berlin");

window.moment = moment;

app.use(router)
    .use(store);
// .mixin(TranslateMixin)
app.mount('#app')
