import Fair from '@/models/Fair';
import FairProfile from '@/models/FairProfile';
import Program from '@/models/Program';
// @ts-ignore
import io from 'socket.io-client';
import {store} from '@/store/store';
import {eagerLoadModels} from '@/store/helpers/actions';
import {BaseModel} from '@/models/BaseModel';
import {BaseModelInterface} from '@/models/BaseModelInterface';
import {
    IActiveChat,
    IActiveVideo,
    ISocketChatContactUpdate,
    ISocketChatEnd,
    ISocketChatMessage,
    ISocketChatRead,
    ISocketCompanyState,
    ISocketRequestChat,
    ISocketUserState
} from '@/types/socket';
import {TrackFairEventType, TrackFairProfileEventType} from '@/api/socket/Client';

class Socket {
    /**
     *
     * @type {null|SocketIOClient.Socket}
     */
    socket: any = null;
    commandQueue: Array<Record<string, unknown>> = [];
    token: string | null = null;
    connected: boolean = false;

    log(...args: any[]) {
        if (process.env.NODE_ENV === 'development' && typeof console !== 'undefined') {
            // eslint-disable-next-line no-console
            console.log(...args);
        }
    }

    constructor(token: string | null = null) {
        this.token = token;

        if (this.token !== null) {
            this.connect();
        }
    }

    connect(token?: string): boolean {
        // if it's already connected.. do nothing ¯\_(ツ)_/¯
        if (this.socket !== null) {
            return false;
        }

        if (token) {
            this.token = token;
        }

        if (!this.token) {
            return false;
        }


        // establish the connection
        // this.socket = io.connect('http://anubarak.s:8080', {query: 'token=' + this.token});
        this.socket = io.connect(process.env.VUE_APP_SOCKET + 'fair-' + store.getters['fairs/current'].id, {
            query: 'token=' + this.token,
            // the current dev server is not always available -> do not reconnect on dev.. it only fills the console
            reconnection: process.env.NODE_ENV !== 'development',
        });
        this.connected = true;
        console.log('connect to socket');
        this.socket.on('reconnect_attempt', () => {
            if (this.socket !== null) {
                this.socket.io.opts.query = {token: this.token};
            }
        });

        /**
         * Create a new model and store it “ヽ(´▽｀)ノ”'
         *
         * @param {BaseModel}       Model
         * @param {{}[]}            data
         * @return {Promise<BaseModel[]>}
         */
        const createModel = async (Model: BaseModelInterface, data: any[] | any) => {

            let models: any[] = Array.isArray(data) === false ? [data] : data;
            let eagerLoaded = eagerLoadModels(models, Model);

            this.log('create model', Model.getEntity(), eagerLoaded);
            // return await (new Model(data)).getReferenceInStore();
            return store.dispatch(Model.getEntity() + '/set', eagerLoaded);
        };

        // /**
        //  * Save a Project
        //  */
        // this.socket.on('saveProject', (data) => {
        //     createModel(Project, data).then(model => {
        //         this.log('inserted project', model);
        //     });
        // });
        //
        // /**
        //  * Delete a Project
        //  */
        // this.socket.on('deleteProject', async (data) => {
        //     this.log('deleting project', data);
        //     store.dispatch('projects/remove', data);
        // });
        //
        const events: any = [
            {name: 'Fair', model: Fair},
            {name: 'FairProfile', model: FairProfile},
            {name: 'Program', model: Program},
        ];

        events.forEach((event: any) => {
            const {name, model}: { name: string, model: BaseModelInterface } = event;
            if (this.socket !== null) {
                this.socket.on(`save${name}`, (data: Object) => {
                    createModel(model, data).then(model => {
                        this.log('inserted' + name, model);
                    });
                });

                this.socket.on(`delete${name}`, async (data: Object) => {
                    this.log('deleting ' + name, data);
                    store.dispatch(model.getEntity() + '/remove', data);
                });
            }
        });


        // special thaff
        if (this.socket !== null) {
            this.socket.on('state', (message: ISocketUserState) => this.onUserState(message));
            this.socket.on('company', (message: ISocketCompanyState) => this.onCompanyMessage(message));
            this.socket.on('requestChat', (message: ISocketRequestChat) => this.onRequestChat(message));
            this.socket.on('requestVideo', (message: ISocketRequestChat) => this.onRequestVideo(message));
            this.socket.on('activeChat', (message: IActiveChat) => this.onActiveChat(message));
            this.socket.on('activeVideo', (message: IActiveVideo) => this.onActiveVideo(message));
            this.socket.on('videoEnded', (message: ISocketChatEnd) => this.onVideoEnd(message));
            this.socket.on('chatMessage', (message: ISocketChatMessage) => this.onChatMessage(message));
            this.socket.on('chatEnded', (message: ISocketChatEnd) => this.onChatEnd(message));
            this.socket.on('setRead', (message: ISocketChatRead) => this.onChatRead(message));
            this.socket.on('chatContactUpdate', (message: ISocketChatContactUpdate) => this.onChatContactUpdate(message));
        }

        return true;
    }

    onChatRead(message: ISocketChatRead) {
        return store.dispatch('socket/chatRead', message);
    }

    onChatContactUpdate(message: ISocketChatContactUpdate) {
        return store.dispatch('chat-contact/loadIfExist', message.id);
    }

    onChatEnd(message: ISocketChatEnd) {
        console.log('SOCKET: chat ended', message);
        return store.dispatch('socket/chatEnd', message);
    }

    onVideoEnd(message: ISocketChatEnd) {
        console.log('SOCKET: video ended', message);
        return store.dispatch('socket/videoEnd', message);
    }

    onChatMessage(data: ISocketChatMessage) {
        console.log('SOCKET: chat message', data);
        return store.dispatch('socket/addChatMessage', data);
    }

    onActiveChat(data: IActiveChat) {
        console.log('SOCKET: chat accepted', data);
        return store.dispatch('socket/addActiveChat', data);
    }

    onActiveVideo(data: IActiveVideo) {
        console.log('SOCKET: video accepted', data);
        return store.dispatch('socket/addActiveVideo', data);
    }

    onUserState(data: ISocketUserState) {
        console.log('SOCKET: got user state', data);
        return store.dispatch('socket/setUserState', data);
    }

    onRequestChat(data: ISocketRequestChat) {
        console.log('SOCKET: got requestChat message', data);
        if (!data.join) {
            // request wieder entfernen, etwas hat nicht geklappt
            store.dispatch('socket/removeRequest', data.companyId);
        }
    }

    onRequestVideo(data: ISocketRequestChat) {
        console.log('SOCKET: got requestVideo message', data);
        if (!data.join) {
            // request wieder entfernen, etwas hat nicht geklappt
            store.dispatch('socket/removeVideoRequest', data.companyId);
        }
    }

    onCompanyMessage(data: ISocketCompanyState) {
        console.log('SOCKET: got company state', data);
        return store.dispatch('socket/setCompanyState', data);
    }

    /**
     * Make the client join rooms
     *
     * @param token
     */
    joinRooms(token: string | null = null) {
        if (this.token !== null && this.token === token) {
            return true;
        }

        if (token === null) {
            token = this.token;
        }

        if (this.socket) {
            this.socket.emit('joinRooms', token);
        }
        // store the token as default for the next request
        // in case of disconnections
        this.token = token;
    }

    joinCompanyRoom(roomName: string) {
        if (this.socket) {
            this.socket.emit('joinCompanyRoom', roomName);
        } else {
            this.commandQueue.push({
                command: 'joinCompanyRoom',
                data: roomName
            });
        }
    }

    leaveCompanyRoom(roomName: string) {
        if (this.socket) {
            this.socket.emit('leaveCompanyRoom', roomName);
        } else {
            this.commandQueue.push({
                'command': 'leaveCompanyRoom',
                'data': roomName
            });
        }
    }

    requestChat(company: string, username: string) {
        if (this.socket) {
            this.socket.emit('requestChat', {company, username});
            store.dispatch('socket/addRequest', {
                companyId: company,
                userId: store.getters['user/id'],
                username: username,
                online: true
            });
        }
    }

    /**
     * Track an event for fairs
     *
     * @param fairId
     * @param profileId
     * @param event
     * @param suffix
     */
    trackFairEvent(fairId: number, profileId: number | null, event: TrackFairEventType | TrackFairProfileEventType, suffix?: string) {
        const data: { fairId: number, profileId: number | null, event: string } = {
            fairId,
            profileId,
            event: suffix ? event + '-' + suffix : event
        };

        if (this.socket && this.socket.connected) {
            this.socket.emit('trackFairEvent', data);
        } else {
            this.commandQueue.push({
                'command': 'trackFairEvent',
                'data': data
            });
        }
    }

    requestVideo(company: string, username: string) {
        if (this.socket) {
            this.socket.emit('requestVideo', {company, username});
            store.dispatch('socket/addVideoRequest', {
                companyId: company,
                userId: store.getters['user/id'],
                username: username,
                online: true
            });
        }
    }

    sendChatMessage(companyId: string, userId: string, message: string) {
        if (this.socket) {
            this.socket.emit('chatMessage', {companyId, userId, message});
        }
    }

    sendChatEnd(companyId: string, userId: string) {
        if (this.socket) {
            this.socket.emit('endChat', {companyId, userId});
        }
    }

    sendVideoEnd(companyId: string, userId: string) {
        if (this.socket) {
            this.socket.emit('endVideo', {companyId, userId});
        }
    }

    sendChatRead(companyId: string, userId: string) {
        if (this.socket) {
            this.socket.emit('setRead', {companyId, userId});
        }
    }

    videoConnected(companyId: string, userId: string) {
        if (this.socket) {
            this.socket.emit('videoConnected', {companyId, userId});
        }
    }


    reconnect(token: string | null = null) {
        if (token !== null && !this.token) {
            this.token = token;
        }

        if (this.socket !== null && this.socket.connected === false) {
            this.socket.connect();
        }
    }

    sendCommandQueue() {
        if (!this.socket) {
            return;
        }

        for (const command of this.commandQueue) {
            this.socket.emit(command.command, command.data);
        }
    }

    disconnect() {
        if (this.socket !== null && this.socket.connected === true) {
            this.socket.disconnect();
        }
    }
}

export default Socket;
