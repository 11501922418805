import apiClient from "@/api/client";
import {serialize} from "@/services/Request";
import {BaseModel} from "@/models/BaseModel";

export class BaseEndpoint {

    endpoint: string = '';

    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    /**
     * Delete a model
     *
     * @param id
     * @return {Promise<AxiosResponse<any>>}
     */
    public delete(id: number) {
        return apiClient.delete(`${this.endpoint}/${id}`);
    }
    /**
     * Saves a User
     *
     * @param {BaseModel} data
     * @return {AxiosPromise<any>}
     */
    public save(data: BaseModel) {
        return apiClient.post(this.endpoint, data);
    }
    /**
     * Searches data with the given criteria
     *
     * @param params
     * @return {AxiosPromise<any>}
     */
    public all(params = {}) {
        let url = serialize(params);
        return apiClient.get(this.endpoint + '?' + url);
    }
    /**
     * Fetch data by ID
     *
     * @param {Number}  id
     * @param {Object}  params
     * @return {AxiosPromise<any>}
     */
    public one(id: Number, params?: Object) {
        let url = serialize(params);
        return apiClient.get(`${this.endpoint}/${id}?${url}`);
    }

    /**
     * Fetch data by slug
     *
     * @param {string}  slug
     * @param {Object}  params
     * @return {AxiosPromise<any>}
     */
    public slug(slug: string, params: any = {}) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const searchParams = Object.fromEntries(urlSearchParams.entries());
        const xPreview = searchParams['x-craft-preview']?? null;
        const token = searchParams['token']?? null;
        if(token !== null){
            params.token = token;
        }
        if(xPreview !== null){
            params['x-craft-preview'] = xPreview;
        }

        let url = serialize(params);
        return apiClient.get(`${this.endpoint}/slug/${slug}?${url}`);
    }
}
