

/**
 * Toggles an element in an array, either push or slice it
 *
 * @param {Array}   array
 * @param {number}  element
 * @return {Array}
 */
export function toggleElement(array: any[], element: string | number) {
    const index = array.findIndex((el: string | number) => el === element);
    if (index === -1) {
        array.push(element);
    } else {
        array.splice(index, 1);
    }

    return array;
}

/**
 * Remove elements from an 1d array
 *
 * @param arr
 * @return {*}
 */
export function remove(arr: any) {
    let what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function getColumn(array: any[] = [], name: string) {
    let response: any[] = [];
    array.forEach(el => {
        if (Object.prototype.hasOwnProperty.call(el, name) === true) {
            response.push(el[name])
        }
    });

    return response;
}