import {Link} from "@/interfaces/Link";
import {BaseModel} from "@/models/BaseModel";
import FairCategory from "@/models/FairCategory";
import moment from "moment";

export default class FairProfile extends BaseModel {

    /**
     * The name of the store Module
     *
     * @type {null|string}
     * @private
     */
    static _entity = 'fair-profiles';

    public fairId: number | null = null;
    public uid: string = '';
    public slug: string | null = null;

    public subline: string | null = null;
    public imageUrl: string | null = null;
    public companyLogoUrl: string | null = null;
    public text: string | null = null;
    public companySubline: string | null = null;
    public companyUrl: string | null = null;
    public video: string | null = null;
    public downloads: { id: number, url: string, title: string }[] = [];
    public fairCategoryIds: number[] = [];
    public socialUrls: { facebookUrl: string, youtubeUrl: string, twitterUrl: string, instagramUrl: string, xingUrl: string, linkedInUrl: string } | null = null;

    public contactPartners: { id: number, title: string, firstName: string, lastName: string, email: string, phone: string | null }[] = [];
    public companyLink: string | null = null;
    public companyStreet: string | null = null;
    public companyHouseNumber: string | null = null;
    public companyLocation: string | null = null;
    public companyZip: string | null = null;

    public avatarUrl: string | null = null;
    public linkToThaff: boolean = false;
    public hasChat: boolean = false;
    public hasVideo: boolean = false;

    public jobCategoryIds: number[] = [];

    jobs: {
        id: number,
        title: string | null,
        category: string | null,
        location: string | null
    }[] = [];

    constructor(...args: any[]) {
        super(...args);
        this.init(...args);
    }

    public isPlaceHolder: boolean = false;

    init(...args: any[]) {
        super.init(...args);
    }


    /**
     * Get the fair categories for this profile
     *
     * @return {FairCategory[]}
     */
    get fairCategories() {
        return this.getMany({
            attribute: '_fairCategories',
            entity: FairCategory,
            ids: this.fairCategoryIds
        })
    }

    /**
     * Set the fair categories for this profile
     *
     * @param {FairCategory[]} models
     */
    set fairCategories(models) {
        this.setMany({
            attribute: '_fairCategories',
            entity: FairCategory,
            models
        })
    }

    get logoUrl() {
        return this.companyLogoUrl ? this.companyLogoUrl : this.imageUrl;
    }
}
