import {store} from "@/store/store";
import {createNewModel} from "@/store/helpers/actions";
import {BaseModel} from "@/models/BaseModel";
import {BaseModelInterface, ClassType, ModelCreateInterface} from "@/models/BaseModelInterface";
import CacheManager from "@/services/CacheManager";
import {StateInterface} from "@/store/helpers/getters";

/**
 * Called when a model in the store is overwritten by a new one from the server
 *
 * @param {BaseModel} elementInStore
 * @param {BaseModel} newElement
 * @param {BaseModel[]} [modelsStore]
 * @param {Number} [index]
 */
export const setValues = (elementInStore: BaseModelInterface, newElement: BaseModel | any, modelsStore = null, index = null) => {
    elementInStore.populate(newElement)
};

/**
 * Set a single Attribute for a model in the store
 *
 * @param modelsStorage
 * @param model
 * @param attribute
 * @param value
 */
export const setAttribute = (modelsStorage: BaseModelInterface[], model: BaseModelInterface, attribute: string, value: any) => {
    let index = modelsStorage.findIndex(el => {
        return el.id === model.id;
    });


    if (index !== -1) {
        // Vue.set(modelsStorage[index], attribute, value);
        modelsStorage[index][attribute] = value;
    }
};
/**
 * Set multiple attributes for Models in the store
 *
 * @param modelsStorage
 * @param attributes
 * @param model
 */
export const setAttributes = (modelsStorage: BaseModelInterface[], attributes: { [key: string]: string }, model: BaseModelInterface) => {
    let index = modelsStorage.findIndex(el => {
        return el.id === model.id;
    });

    if (index !== -1) {
        Object.getOwnPropertyNames(attributes).forEach(
            (attribute) => {
                modelsStorage[index][attribute] = attributes[attribute];
                // Vue.set(modelsStorage[index], attribute, attributes[attribute]);
            }
        );
    }
};

/**
 * Remove one or multiple values from the store
 *
 * @param modelsStorage
 * @param {number} id
 */
export const removeModel = (modelsStorage: BaseModelInterface[], id: number) => {
    let index = modelsStorage.findIndex(el => {
        return el.id === id;
    });

    if (index !== null && index !== -1) {

        // Vue.delete(modelsStorage, index);
        modelsStorage.splice(index, 1);
    }
};


// const toggleReactivity =
//     (obj) => {
//         let stopUpdating = true;
//         for (let fieldName in obj) {
//             if (!obj.hasOwnProperty(fieldName)) {
//                 return
//             }
//
//             let property = Object.getOwnPropertyDescriptor(obj, fieldName);
//             if (property && property.configurable === false) {
//                 return
//             }
//             let getter = property && property.get;
//             let setter = property && property.set;
//
//             if (getter && typeof getter.call(obj) === 'object') {
//                 toggleReactivity(getter.call(obj))
//             }
//
//             //console.log(setter);
//             if (setter) {
//                 Object.defineProperty(obj, fieldName, {
//                     configurable: false,
//                     enumerable: true,
//                 })
//             }
//         }
//     };

/**
 * Find a Model and update or insert a new one
 *
 * @param {BaseModel[]}     models
 * @param {BaseModel}       newModel
 * @param {string[]}        [forcedAttributes]
 */
export const insertOrUpdate = (models: BaseModelInterface[], newModel: BaseModelInterface, forcedAttributes = []) => {
    const index = models.findIndex(el => el.id === newModel.id);

    if (index !== null && index !== -1) {


        models[index].populate(newModel);
    } else {
        models.push(newModel);
        // Vue.set(models, models.length, newModel);
    }
};
/**
 * returns the default Mutation handlers
 * @param cacheManager          The cacheManager for caching
 * @param Model                 The model that is used by the module
 * @param {String}  entity      The name of the module
 * @param {{}}                  [options]
 * @return {{SET(*, (Array|BaseModel)): void, SET_ATTRIBUTE(*, {attribute?: *, value?: *, model?: *}): void, SET_HASH(*, *): void, REMOVE(*, *=): void, SET_ATTRIBUTES(*, {attributes?: *, model?: *}): void, CLEAR(*): void}}
 */
export const defaultMutations = (cacheManager: CacheManager | null, Model: BaseModelInterface | ClassType | ModelCreateInterface | null = null, entity: string | null = null, options = {}): { [key: string]: Function } => ({
    /**
     * Set models in the store
     *
     * @param state
     * @param  {Array|BaseModel} objects
     */
    SET(state: StateInterface, objects: any) {
        if (Array.isArray(objects) === false) {
            objects = [objects];
        }

        //let randomNumber = Math.random();
        // let randomNumber = '';
        // if(entity){
        //      console.time(entity + randomNumber);
        // }
        objects.forEach((element: BaseModelInterface) => {
            if (Model !== null && !(element instanceof BaseModel)) {
                const e = createNewModel(Model, element);
                if (e !== null) {
                    element = e;
                }
            }

            if (typeof element.populate !== 'function') {
                console.error('Element is missing it`s populate function', element);
            }

            insertOrUpdate(state.models, element);
        });

        if (entity !== null && typeof state.hash !== 'undefined' && cacheManager !== null) {
            store.commit(entity + '/SET_HASH', cacheManager.getHash(state.models));
        }
    },
    /**
     * Clear the store, reset everything
     *
     * @param state
     * @constructor
     */
    CLEAR(state: StateInterface) {
        state.models = [];
        if (cacheManager) {
            cacheManager.clear();
        }
        state.hash = '';
    },
    /**
     *
     * @param state
     * @param hash
     * @param generate
     * @constructor
     */
    SET_HASH(state: StateInterface, hash: string) {
        state.hash = hash;
    },
    /**
     * Remove a model from the store
     *
     * @param state
     * @param id
     * @constructor
     */
    REMOVE(state: StateInterface, id: number) {
        removeModel(state.models, id);
        if (entity !== null && typeof state.hash !== 'undefined' && cacheManager !== null) {
            store.commit(entity + '/SET_HASH', cacheManager.getHash(state.models), {root: true});
        }
    },
    /**
     * Set an attribute for a model in the store
     *
     * @param state
     * @param attribute
     * @param value
     * @param model
     * @constructor
     */
    SET_ATTRIBUTE(state: StateInterface, {
        attribute,
        value,
        model
    }: { attribute: string, value: any, model: BaseModelInterface }) {
        setAttribute(state.models, model, attribute, value);
    },
    /**
     * Set multiple attributes for a model in the store
     *
     * @param state
     * @param attributes
     * @param model
     * @constructor
     */
    SET_ATTRIBUTES(state: StateInterface, {
        attributes,
        model
    }: { attributes: { [key: string]: string }, model: BaseModelInterface }) {
        setAttributes(state.models, attributes, model);
    }
});