import Api from "@/api/endpoints/Programs";
import {BaseModel} from "@/models/BaseModel";
import Model from "@/models/Program";
import {defaultActions} from "@/store/helpers/actions";
import {defaultMutations} from "@/store/helpers/mutations";
import {defaultGetters} from "@/store/helpers/getters";
import CacheManager from "@/services/CacheManager";

const cacheManager = new CacheManager({defaultCacheDuration: 300});
//////////////////////////////////////////////////
//   ____ ____ ____ ____ ____ ____ ____
//  ||G |||e |||t |||t |||e |||r |||s ||
//  ||__|||__|||__|||__|||__|||__|||__||
//  |/__\|/__\|/__\|/__\|/__\|/__\|/__\|
//
//////////////////////////////////////////////////
const {
    all: getAll,
    one: getOne,
    oneBySlug: getOneBySlug,
    notInStore: getNotInStore,
    isInStore: getIsInStore,
    getModelsInStore: getModelsInStore,
    allByIds: getAllByIds,
} = defaultGetters();

//////////////////////////////////////////////////
//   ____ ____ ____ ____ ____ ____ ____
//  ||A |||c |||t |||i |||o |||n |||s ||
//  ||__|||__|||__|||__|||__|||__|||__||
//  |/__\|/__\|/__\|/__\|/__\|/__\|/__\|
//
//////////////////////////////////////////////////
const {
    setAction,
    oneAction,
    removeAction,
    deleteAction,
    clearAction,
    allAction,
    saveAction,
    oneBySlugAction,
}: { [key: string]: Function } = defaultActions({
    Api,
    cacheManager,
    Model
});

//////////////////////////////////////////////////
//   ____ ____ ____ ____ ____ ____ ____ ____ ____
//  ||M |||u |||t |||a |||t |||i |||o |||n |||s ||
//  ||__|||__|||__|||__|||__|||__|||__|||__|||__||
//  |/__\|/__\|/__\|/__\|/__\|/__\|/__\|/__\|/__\|
//
//////////////////////////////////////////////////
const mutations = defaultMutations(cacheManager, Model, Model.getEntity());

interface stateInterface {
    models: BaseModel[];
    hash: string | number;
}

//////////////////////////////////////////////////
//   ____ ____ ____ ____ ____
//  ||S |||t |||a |||t |||e ||
//  ||__|||__|||__|||__|||__||
//  |/__\|/__\|/__\|/__\|/__\|
//
//////////////////////////////////////////////////
const state: stateInterface = {
    models: [],
    hash: 0,
};

export default {
    namespaced: true,
    actions: {
        set: setAction,
        one: oneAction,
        'one-by-slug': oneBySlugAction,
        remove: removeAction,
        delete: deleteAction,
        clear: clearAction,
        all: allAction,
        save: saveAction,
    },
    mutations,
    getters: {
        all: getAll,
        one: getOne,
        oneBySlug: getOneBySlug,
        notInStore: getNotInStore,
        isInStore: getIsInStore,
        getModelsInStore,
        allByIds: getAllByIds,
    },
    state,
};
