import {Link} from "@/interfaces/Link";
import {BaseModel} from "@/models/BaseModel";
import moment from "moment";

export default class Program extends BaseModel {

    /**
     * The name of the store Module
     *
     * @type {null|string}
     * @private
     */
    static _entity = 'programs';

    public imageUrl: null | string = null;
    public text: null | string = null;
    public startDate: null | moment.Moment = null;
    public endDate: null | moment.Moment = null;
    public link: null | Link = null;

    public fairId: number | null = null;

    constructor(...args: any[]) {
        super(...args);
        this.init(...args);
    }

    init(...args: any[]) {
        super.init(...args);
    }

    getDateAttributes(): any {
        let attributes = super.getDateAttributes();
        attributes.push('startDate');
        attributes.push('endDate');

        return attributes;
    }
}
