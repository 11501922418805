// @ts-ignore
import Hash from 'object-hash';
import moment from 'moment';
import {BaseModel} from "@/models/BaseModel";
import {BaseModelInterface} from "@/models/BaseModelInterface";

/**
 * A CacheManager to cache Promises
 */
class CacheManager{
    cachedResults: {time: number, hash: string|null, duration: number, promise: Promise<BaseModelInterface|null>}[] = [];

    defaultCacheDuration = 10;

    constructor(properties: {defaultCacheDuration?: number} = {}){
        Object.assign(this, properties);
    }

    /**
     * Generates a Hash for an Object
     *
     * @param params
     */
    getHash(params = {}){
        if(Array.isArray(params) && params.length > 0 && params[0] instanceof BaseModel){
            let data: string[] = [];
            params.forEach(el => {
                if(el.dateUpdated === null){
                    console.error(el);
                    throw new Error('The object has no required DateUpdated attribute');
                }

                let index = moment.isMoment(el.dateUpdated)? el.dateUpdated.format('X') : el.dateUpdated;

                data.push(index)
            });
            params = data;
        }


        return Hash(params);
    }

    /**
     * Deletes all cached results
     */
    clear(hash = null){
        if(hash === null){
            this.cachedResults = [];
        }else{
            let index = this.cachedResults.findIndex( el => {
                return el.hash === hash
            });
            this.cachedResults.splice(index, 1);
        }
    }

    /**
     * Store a value in the Cache, it's important: you can only store promises this way
     *
     * @param {Promise} promise         - The Promise you want to cache
     * @param {Object}  params          - certain Params for the search, request or Body params, if no hash is provided
     *                                      Those Params are hashed
     * @param {String|Null} hash        - The hash Value, used as identifier to receive the correct promise
     * @param {Number} duration         - The duration how long this value remains in the Cache
     */
    cache(promise: Promise<any>, params = {}, hash: string|null = null, duration: number|null = null){
        if(hash === null){
            hash = Hash(params);
        }

        if(duration === null){
            duration = this.defaultCacheDuration;
        }

        this.cachedResults.push({
            time: moment().unix(),
            promise: promise,
            hash: hash,
            duration: duration
        })
    }

    /**
     * Get a cached promise back
     *
     * @param {Object|String}  params   - Either an Object with search Params or a Hash directly
     * @return {{time: Number, promise: Promise, hash: String, duration: Number}|Null}
     */
    getCached(params: object|string = {}){
        let hash = '';
        if(typeof params === 'object'){
            hash = Hash(params);
        }else if(typeof params === 'string'){
            hash = params;
        }

        let index = this.cachedResults.findIndex( el => {
            return el.hash === hash
        });

        if(index !== -1){
            let cached = this.cachedResults[index];

            let now = moment().unix();
            if(typeof cached !== 'undefined' && cached !== null && (now - cached.duration <= cached.time)){
                return cached;
            }else{
                this.cachedResults.splice(index, 1);
            }
        }

        return null;
    }
}

export default CacheManager;
