import {Commit, Dispatch} from "vuex";
import {
    IActiveChat,
    IActiveVideo,
    IChatRequest,
    ISocketChatEnd,
    ISocketChatMessage,
    ISocketChatRead,
    ISocketCompanyState,
    ISocketUserState
} from "@/types/socket";

const setCompanyState = ({commit}: { commit: Commit }, payload: ISocketCompanyState) => {
    commit('SET_COMPANY_STATE', payload);
}

const setUserState = ({commit, dispatch}: { commit: Commit, dispatch: Dispatch }, payload: ISocketUserState) => {
    for (const chatItem of payload.activeChats) {
        dispatch('chat-contact/load', chatItem.chatContactId, {root: true});
    }
    for (const chatItem of payload.activeVideos) {
        dispatch('chat-contact/load', chatItem.chatContactId, {root: true});
    }
    commit('SET_USER_STATE', payload);
}

const addRequest = ({commit}: { commit: Commit }, payload: IChatRequest) => {
    commit('ADD_REQUEST', payload);
}

const addVideoRequest = ({commit}: { commit: Commit }, payload: IChatRequest) => {
    commit('ADD_VIDEO_REQUEST', payload);
}

const removeRequest = ({commit}: { commit: Commit }, payload: string) => {
    commit('REMOVE_REQUEST', payload);
}

const removeVideoRequest = ({commit}: { commit: Commit }, payload: string) => {
    commit('REMOVE_VIDEO_REQUEST', payload);
}

const addChatMessage = ({commit}: { commit: Commit }, payload: ISocketChatMessage) => {
    commit('ADD_CHAT_MESSAGE', payload);
}

const addActiveChat = ({commit, dispatch}: { commit: Commit, dispatch: Dispatch }, payload: IActiveChat) => {
    commit('ADD_ACTIVE_CHAT', payload);
    dispatch('chat-contact/load', payload.chatContactId, {root: true});
};

const addActiveVideo = ({commit, dispatch}: { commit: Commit, dispatch: Dispatch }, payload: IActiveVideo) => {
    commit('ADD_ACTIVE_VIDEO', payload);
    dispatch('chat-contact/load', payload.chatContactId, {root: true});
};

const chatEnd = ({commit}: { commit: Commit }, payload: ISocketChatEnd) => {
    commit('SET_CHAT_END', payload);
};

const videoEnd = ({commit}: { commit: Commit }, payload: ISocketChatEnd) => {
    commit('SET_VIDEO_END', payload);
};

const chatRead = ({commit}: { commit: Commit }, payload: ISocketChatRead) => {
    commit('SET_CHAT_READ', payload);
};

const mutations = {
    SET_COMPANY_STATE(state: ISocketState, payload: ISocketCompanyState) {
        state.companyStates.set(payload.companyId, payload);
    },
    SET_USER_STATE(state: ISocketState, payload: ISocketUserState) {
        state.chatQueue.clear();
        for (const queueItem of payload.chatQueue) {
            state.chatQueue.set(queueItem.companyId, queueItem);
        }
        state.activeChats.clear();
        for (const queueItem of payload.activeChats) {
            state.activeChats.set(queueItem.companyId, queueItem);
        }
        state.videoQueue.clear();
        for (const queueItem of payload.videoQueue) {
            state.videoQueue.set(queueItem.companyId, queueItem);
        }
        state.activeVideos.clear();
        for (const queueItem of payload.activeVideos) {
            state.activeVideos.set(queueItem.companyId, queueItem);
        }
        state.initialized = true;
    },
    ADD_REQUEST(state: ISocketState, payload: IChatRequest) {
        state.chatQueue.set(payload.companyId, payload)
    },
    ADD_VIDEO_REQUEST(state: ISocketState, payload: IChatRequest) {
        state.videoQueue.set(payload.companyId, payload)
    },
    REMOVE_REQUEST(state: ISocketState, payload: string) {
        if (state.chatQueue.has(payload)) {
            state.chatQueue.delete(payload)
        }
    },
    REMOVE_VIDEO_REQUEST(state: ISocketState, payload: string) {
        if (state.videoQueue.has(payload)) {
            state.videoQueue.delete(payload)
        }
    },
    ADD_CHAT_MESSAGE(state: ISocketState, payload: ISocketChatMessage) {
        if (!state.activeChats.has(payload.companyId)) {
            return;
        }
        const activeChat = state.activeChats.get(payload.companyId);

        activeChat?.messages.push(payload.message)
    },
    ADD_ACTIVE_CHAT(state: ISocketState, payload: IActiveChat) {
        // aus queue löschen und ins aktive setzen
        if (state.chatQueue.has(payload.companyId)) {
            state.chatQueue.delete(payload.companyId);
        }
        state.activeChats.set(payload.companyId, payload);
    },
    ADD_ACTIVE_VIDEO(state: ISocketState, payload: IActiveVideo) {
        // aus queue löschen und ins aktive setzen
        if (state.videoQueue.has(payload.companyId)) {
            state.videoQueue.delete(payload.companyId);
        }
        state.activeVideos.set(payload.companyId, payload);
    },
    SET_CHAT_END(state: ISocketState, payload: ISocketChatEnd) {
        if (!state.activeChats.has(payload.companyId)) {
            return;
        }
        if (payload.endClient === payload.userId) {
            // nutzer hat es selbst beenden, aus dem store kicken
            state.activeChats.delete(payload.companyId);
        } else {
            // durch company beendet, erstmal nur markieren für nutzer
            const activeChat = state.activeChats.get(payload.companyId);
            if (activeChat) {
                activeChat.end = true;
                activeChat.endClient = payload.endClient;
            }
        }
    },
    SET_VIDEO_END(state: ISocketState, payload: ISocketChatEnd) {
        if (!state.activeVideos.has(payload.companyId)) {
            return;
        }
        // nutzer hat es selbst beenden, aus dem store kicken
        state.activeVideos.delete(payload.companyId);
    },
    SET_CHAT_READ(state: ISocketState, payload: ISocketChatRead) {
        if (!state.activeChats.has(payload.companyId)) {
            return;
        }
        const activeChat = state.activeChats.get(payload.companyId);
        if (activeChat) {
            activeChat.readIndex = payload.readIndex;
        }
    },
};

interface ISocketState {
    companyStates: Map<string, ISocketCompanyState>
    chatQueue: Map<string, IChatRequest>
    activeChats: Map<string, IActiveChat>
    videoQueue: Map<string, IChatRequest>
    activeVideos: Map<string, IActiveVideo>
    initialized: boolean
}

const state: ISocketState = {
    companyStates: new Map(),
    chatQueue: new Map(),
    videoQueue: new Map(),
    activeVideos: new Map(),
    activeChats: new Map(),
    initialized: false
}

export default {
    namespaced: true,
    actions: {
        setCompanyState,
        setUserState,
        addRequest,
        addVideoRequest,
        removeRequest,
        addChatMessage,
        addActiveChat,
        chatEnd,
        videoEnd,
        chatRead,
        removeVideoRequest,
        addActiveVideo
    },
    mutations,
    getters: {
        activeChats(state: ISocketState) {
            return [...state.activeChats.values()];
        },
        activeVideos(state: ISocketState) {
            return [...state.activeVideos.values()];
        },
        activeChatByCompany(state: ISocketState) {
            return (companyId: string) => {
                if (!state.activeChats.has(companyId)) {
                    return null;
                }

                return state.activeChats.get(companyId)
            }
        },
        chatRequestByCompany(state: ISocketState) {
            return (companyId: string) => {
                if (!state.chatQueue.has(companyId)) {
                    return null;
                }

                return state.chatQueue.get(companyId)
            }
        },
        activeVideoByCompany(state: ISocketState) {
            return (companyId: string) => {
                if (!state.activeVideos.has(companyId)) {
                    return null;
                }

                return state.activeVideos.get(companyId)
            }
        },
        activeVideoByRoomUrl(state: ISocketState) {
            return (roomUrl: string) => {
                for (const activeVdeo of state.activeVideos.values()) {
                    if (activeVdeo.roomUrl === roomUrl) {
                        return activeVdeo;
                    }
                }

                return null
            }
        },
        videoRequestByCompany(state: ISocketState) {
            return (companyId: string) => {
                if (!state.videoQueue.has(companyId)) {
                    return null;
                }

                return state.videoQueue.get(companyId)
            }
        },
        companyState(state: ISocketState) {
            return (companyId: string) => {
                if (!state.companyStates.has(companyId)) {
                    return null;
                }
                return state.companyStates.get(companyId)
            }
        },
        isInitialized(state: ISocketState) {
            return state.initialized;
        }
    },
    state,
};
