import {getValue} from "@/services/ObjectHelper";
import {key, store} from '@/store/store';
import moment from "moment";
import {hasOwnProperty} from "@/services/Utils";
// @ts-ignore
import cloneDeep from 'lodash.clonedeep';
import {
    BaseModelInterface, ClassType,
    EagerLoadOption,
    ModelCreateInterface,
    StaticImplements,
    StaticModelInterface
} from "@/models/BaseModelInterface";


export interface BaseModelConstructor<T extends BaseModel> {
    new(): T;
}

@StaticImplements<StaticModelInterface>()
export class BaseModel implements BaseModelInterface {
    [key: string]: any

    static dateAttributes = ['dateCreated', 'dateUpdated'];
    /**
     * @type {moment.Moment}
     */
    public dateCreated: null | moment.Moment = null;
    /**
     * @type {moment.Moment}
     */
    public dateUpdated: null | moment.Moment = null;

    /**
     * An object that represents the errors this object has
     *
     * @type {{}}
     */
    public errors: { [key: string]: string[] } = {};

    /**
     * the slug of the model
     */
    public slug: string | null = null;

    /**
     * ID
     *
     * @type {Number|null}
     */
    public id: number | null = null;

    /**
     * The title
     *
     * @type {null|string}
     */
    public title: string | null = null;

    static _uuid = 0;
    private _uuid: number | null = null;

    /**
     * An array of Getters for this class, used for caching
     *
     * @type {null|[]}
     * @private
     */
    static _allGetters: string[] | null = null;


    /**
     * An array of Setters for this class, used for caching
     *
     * @type {null|[]}
     * @private
     */
    static _allSetters: string | null = null;

    /**
     * The name of the store Module
     *
     * @type {null}
     * @private
     */
    static _entity: string = '';

    /**
     * The Sort order
     */
    sortOrder: number | null = null;

    fieldDeltas: string[] | null = null;

    initialized: boolean = false

    constructor(...args: any[]) {
        // needs this because of how Es6 is build
    }

    clone() {
        //const newClass = Object.assign(Object.create(Object.getPrototypeOf(this)), this);
        const newClass = cloneDeep(this);
        newClass.generateUid();
        return newClass;
    }

    init(data: any = {}) {
        this.generateUid();
        this.populate(data);
        this.initialized = true;
    }

    /**
     * populates the Data of this Model by another Object
     * this can either be a normal object or an instance of BaseModel
     *
     * @param {Object|BaseModel} data
     */
    populate(data: BaseModel | { [key: string]: any } = {}) {
        for (let i in data) {
            if (hasOwnProperty(data, i) === true && i !== '_uuid' && i !== '_parentUuid') {
                // direct property of the class
                if (hasOwnProperty(this, i) === true) {
                    //Profile.start('populate-' + data.id + '-' + i);
                    this[i] = data[i];
                    //Profile.end('populate-' + data.id + '-' + i);
                } else {
                    const descriptor = this.getPropertyDescriptor(this, i);
                    if (typeof descriptor !== 'undefined' && typeof descriptor.set === 'function') {
                        this[i] = data[i];
                    }
                }
            }
        }

        /**
         * Convert DateTime Attributes correctly
         */
        this.getDateAttributes().forEach((attr: any) => {
                if (typeof this[attr] !== 'undefined') {
                    this[attr] = this.setTimeValue(attr, this[attr]);
                }
            }
        );
    }

    protected setTimeValue(attr: string, value: moment.Moment | string | null | number): null | moment.Moment {
        if (value) {
            if (moment.isMoment(value)) {
                value = value.clone();
            } else if (Number.isInteger(value)) {
                // @ts-ignore
                value = moment.unix(value);
            } else {
                value = moment(value);
            }

            return value;
        }

        return null;
    }


    /**
     * Basic attributes that should be send in every ajax request
     */
    getBaseAttributes(): string[] {
        return [
            'id',
            'dateUpdated'
        ]
    }

    /**
     * Internal attributes that should not be send via ajax request
     */
    getInternalAttributes(): string[] {
        return [
            'errors',
            '_uuid'
        ]
    }

    /**
     * Get property Descriptor of this Model or it's prototype
     *
     * @param obj
     * @param prop
     * @return {PropertyDescriptor}
     */
    getPropertyDescriptor(obj: BaseModelInterface, prop: string): PropertyDescriptor | undefined {
        let desc;
        do {
            desc = Object.getOwnPropertyDescriptor(obj, prop);
        } while (!desc && (obj = Object.getPrototypeOf(obj)));
        return desc;
    }

    /**
     * Get a related Model
     *
     * @param {BaseModelInterface}               entity              The Store entity
     * @param {Number|number}           id                  The ID of the related Model
     * @param {String}                  attribute           The private attribute that will hold the ID of the model
     * @return {null|BaseModel}
     */
    getOne({
               entity,
               id,
               attribute
           }: { entity: string | StaticModelInterface, id: number | null, attribute: string }) {
        if (id === null) {
            return null;
        }

        // required for vue reactivity
        // eslint-disable-next-line no-unused-vars
        const p = this[attribute];

        if (typeof entity === 'function') {
            // @ts-ignore
            entity = entity.getEntity();
        }
        // check for getters
        const model = store.getters[entity + '/one'](id);
        if (model !== null) {
            return model || null;
        }

        // load it
        store.dispatch(entity + '/one', {id: id})
            .then(response => {
                this.set(attribute, response.id);

                return response;
            })
            .catch(err => {
                console.log(err);
            });

        return null;
    }

    /**
     * Get a related Model
     *
     * @param {String|BaseModel}  entity          The Store entity
     * @param {Number[]}  ids              The ID of the related Model
     * @param {String} attribute        The private attribute that will hold the ID of the model
     * @return {null|*}
     */
    getMany({
                entity,
                ids,
                attribute
            }: { entity: string | StaticModelInterface, ids: number[], attribute: string }) {
        // required for vue reactivity
        // eslint-disable-next-line no-unused-vars
        const p = this[attribute];

        if (typeof entity === 'function') {
            // @ts-ignore
            entity = entity.getEntity();
        }

        // check for getters
        const models = store.getters[entity + '/allByIds'](ids);
        if (models !== null && models.length === ids.length) {
            return models;
        }

        // load it
        store.dispatch(entity + '/all', {filter: {id: ids}})
            .then(response => {
                if (attribute !== null) {
                    this.set(attribute, 'done');
                }

                return response;
            })
            .catch(err => {
                console.log(err);
            });

        return [];
    }

    /**
     * @param entity
     * @param model
     * @param attribute
     */
    setOne({
               entity,
               model,
               attribute
           }: { entity: string | BaseModel | BaseModelInterface | null | ClassType, model: BaseModelInterface | null | ClassType, attribute: string }) {
        if (Array.isArray(model) && model.length === 0) {
            return null;
        }

        this.set(attribute, null);

        if (typeof entity === 'function') {
            if (model && !(model instanceof entity)) {
                model = new entity(model);
            }
            // @ts-ignore
            entity = entity.getEntity();
        }

        if (model) {
            store.dispatch(entity + '/set', model)
                .then(response => {
                    this.set(attribute, response.id);
                })
        }
    }

    /**
     * Set multiple models
     *
     * @param {String}                  attribute
     * @param {BaseModel[]|Object[]}    models
     * @param {BaseModel}               entity
     */
    setMany({
                attribute,
                models,
                entity
            }: { attribute: string, models: BaseModelInterface[], entity: string | StaticModelInterface }) {
        this.set(attribute, []);
        // @ts-ignore
        store.dispatch(entity.getEntity() + '/set', models)
            .then(response => {
                const ids: number[] = [];
                response.forEach((el: BaseModelInterface) => {
                    if (el.id !== null) {
                        ids.push(el.id)
                    }
                });
                this.set(attribute, ids);
            })
    }

    /**
     * Generates a uuid for this Model
     */
    private generateUid() {
        // @ts-ignore
        this._uuid = this.constructor._uuid;
        // @ts-ignore
        this.constructor._uuid++;
    }

    /**
     * get the _uuid for this Model
     *
     * @return {number|null}
     */
    getUid(): number | null {
        return this._uuid;
    }

    /**
     * Return the Model in the store
     *
     * @return {Promise<any>}
     */
    async getReferenceInStore() {
        return await store.dispatch(this.getStoreEntity() + '/set', this);
    }

    /**
     * Check if this model is in the store directly or just a clone
     */
    isInStore(): boolean {
        return store.getters[this.getStoreEntity() + '/isInStore'](this.getUid());
    }

    /**
     * Get the store module of this Model
     *
     * @return {String}
     */
    getStoreEntity(): string {
        // @ts-ignore
        if (!this.constructor._entity) {
            throw new Error('The model has no valid store Identity');
        }

        // @ts-ignore
        return this.constructor._entity;
    }

    /**
     * Get all DateAttributes for this Model
     *
     * @return {[string, string, string, string]}
     */
    getDateAttributes() {
        // @ts-ignore
        return this.constructor.dateAttributes.slice();
    }

    /**
     * Set an attribute
     *
     * @param {string} attribute
     * @param  value
     */
    set(attribute: string | { [key: string]: any }, value?: any | null) {
        if (this.isInStore()) {
            // it is in the store
            if (typeof attribute === 'object' && typeof value === 'undefined') {
                // set multiple attributes
                store.commit(this.getStoreEntity() + '/SET_ATTRIBUTES', {
                    attributes: attribute,
                    model: this
                }, {root: true});
            } else {
                // set a single attribute
                store.commit(this.getStoreEntity() + '/SET_ATTRIBUTE', {attribute, value, model: this}, {root: true});
            }
        } else {
            // it's not in the store -> treat it as a usual model

            if (typeof attribute === 'object' && typeof value === 'undefined') {
                // set multiple
                Object.keys(attribute).forEach(property => {
                    if (hasOwnProperty(this, property)) {
                        this[property] = attribute[property];
                    }
                });
            } else if (typeof attribute === 'string') {
                // set a single attribute
                this[attribute] = value;
            }
        }
    }

    /**
     * Get all Attributes of this Object
     *
     * @param {Boolean} [withGetters]
     * @param {string[]} [fields]
     * @return {{}}
     */
    getAttributes(withGetters = true, fields: string[] = []) {
        let attributes: { [key: string]: string | null } = {};

        let useCustom: boolean = true;
        if (fields.length === 0) {
            useCustom = false;
            fields = Object.keys(this);
        }

        fields.forEach(property => {
            if (hasOwnProperty(this, property) === true && property.charAt(0) !== '_') {
                attributes[property] = this[property];
            }
        });

        // get also all getters
        if (withGetters === true) {
            this.getAllGetters().forEach(property => {
                attributes[property] = this[property];
            });
        }

        if (useCustom === false) {
            // let's add the dates as well “ヽ(´▽｀)ノ”'
            this.getDateAttributes().forEach((property: string) => {
                if (this[property] instanceof moment) {
                    attributes[property] = this[property].format();
                } else {
                    attributes[property] = null;
                }
            });
        }

        return attributes;
    }

    /**
     * Get the name of all getters this model has
     *
     * @return {String[]}
     */
    getAllGetters(): string[] {
        // @ts-ignore
        if (this.constructor._allGetters === null) {
            const all = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this));
            let getters: string[] = [];
            for (let i in all) {
                if (hasOwnProperty(all, i) === true && typeof all[i].get === 'function') {
                    getters.push(i);
                }
            }

            // @ts-ignore
            this.constructor._allGetters = getters;
        }

        // @ts-ignore
        return this.constructor._allGetters;
    }

    /**
     * Get all properties that might be eager loaded
     *
     * @return {EagerLoadOption[]}
     */
    static eagerLoadAttributes(): EagerLoadOption[] {
        return [];
    }

    /**
     * Set eager loaded relations in the store
     *
     * @param models
     * @param properties
     */
    static eagerLoadModels(models: BaseModelInterface[], properties: { [key: string]: BaseModelInterface[] }) {
        let attributes = this.eagerLoadAttributes();

        for (let i in properties) {
            if (hasOwnProperty(properties, i) === true) {
                let models: BaseModelInterface[] = properties[i];

                // search for the correct store
                let config: EagerLoadOption | null = attributes.find((el: EagerLoadOption) => el.attribute === i) || null;

                if (config) {
                    if (Array.isArray(models) && models.length) {
                        store.dispatch(config.entity + '/set', models);
                    }
                }
            }
        }

        return models;
    }

    /**
     * Get all Model relevant attributes for passing them to their endpoints
     * @return {Object}
     */
    getModelAttributes() {
        let attributes: any = {};
        Object.keys(this).forEach((property: string) => {
            if (hasOwnProperty(this, property) === true && property.charAt(0) !== '_') {
                attributes[property] = this[property];
            }
        });


        // let's add the dates as well “ヽ(´▽｀)ノ”'
        this.getDateAttributes().forEach((property: string) => {
            if (this[property] instanceof moment) {
                attributes[property] = this[property].format();
            } else {
                attributes[property] = null;
            }
        });


        return attributes;
    }

    /**
     * Set all attributes of an object
     *
     * @param attributes
     */
    setAttributes(attributes: { [key: string]: any }) {
        this.set(attributes);
    }

    /**
     * Get errors of the response
     *
     * @param {string|string[]|null} [property]
     * @return {Array|*}
     */
    getErrors(property: string | string[] | null = null): { [key: string]: string[] } | string[] | null {
        if (property === null) {
            return this.errors
        }

        return getValue(this.errors, property) || null;
    }

    /**
     * Returns if a property has an error
     *
     * @param {String} property
     * @return {boolean}
     */
    hasErrors(property: string | string[] | null = null): boolean {
        if (property === null) {
            return Object.keys(this.errors).length !== 0;
        }

        const errors = getValue(this.errors, property) || null;
        if (errors === null) {
            return false;
        }
        if (Array.isArray(errors)) {
            return errors.length > 0;
        }

        return Object.keys(errors).length > 0;
    }

    /**
     * Set Errors for this model
     * @param {Object} errors
     */
    setErrors(errors: { [key: string]: string[] }) {
        this.errors = errors;
    }

    /**
     * Adds an error to a model
     *
     * @param {String} field
     * @param {String} error
     */
    addError(field: string, error: string) {
        if (typeof this.errors[field] === 'undefined') {
            this.errors[field] = []
        }

        this.errors[field].push(error);
        this.errors = cloneDeep(this.errors);
    }


    /**
     * Deletes the model
     *
     * @return {Promise<any>}
     */
    delete(): Promise<BaseModelInterface | boolean> {
        return this.performStoreAction('delete', this);
    }

    save(): Promise<BaseModelInterface | boolean> {
        return this.performStoreAction('save', this);
    }

    /**
     * Perform store operations for this model
     *
     * @param action
     * @param payload
     */
    performStoreAction(action: string, payload: any): Promise<BaseModelInterface | boolean> {
        return store.dispatch(this.getStoreEntity() + '/' + action, payload);
    }

    static getEntity() {
        return this._entity;
    }


    /**
     * An array of eager load options
     */
    eagerLoadAttributes() {
        // @ts-ignore
        return this.constructor.eagerLoadAttributes();
    }

    /**
     * Eager load all models
     *
     * @param models
     * @param properties
     */
    eagerLoadModels(models: BaseModelInterface[], properties: { [key: string]: BaseModelInterface[] }) {
        // @ts-ignore
        return this.constructor.eagerLoadModels(models, properties);
    }

    /**
     *
     */
    getEntity() {
        // @ts-ignore
        return this.constructor.getEntity();
    }
}
