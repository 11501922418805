import {BaseModel} from "@/models/BaseModel";

export interface BaseModelRaw {
    [key: string]: any;
}

export interface EagerLoadOption {
    /**
     * The attribute
     */
    attribute: string;

    /**
     * The store entity
     */
    entity: string | BaseModel;
}

// taken from https://github.com/microsoft/TypeScript/issues/34516#issuecomment-778610884
export interface StaticModelInterface {

    /**
     * An array of eager load options
     */
    eagerLoadAttributes(): EagerLoadOption[];

    /**
     * Eager load all models
     *
     * @param models
     * @param properties
     */
    eagerLoadModels(models: BaseModelInterface[], properties: any): void

    /**
     * Get the store entity of the model
     */
    getEntity(): string;
}

export interface ModelCreateInterface {
    create(...args: any[]): BaseModelInterface
}

// taken from https://github.com/microsoft/TypeScript/issues/34516#issuecomment-778610884
export interface ClassType<T = any, ARGS extends any[] = any[]> {
    new(...args: ARGS): T;
}


// taken from https://github.com/microsoft/TypeScript/issues/34516#issuecomment-778610884
/** class decorator to force static fields to be implemented */
export function StaticImplements<T>() {
    return (constructor: T) => { /* nop */
    };
}

export interface PaginatorArray<BaseModelInterface> {
    pagination: { 'current-page': number, 'page-count': number, 'per-page': number, 'total-count': number }
}

export interface BaseModelInterface {
    id: null | number

    init(...args: any[]): void;

    getEntity(): string

    getStoreEntity(): string;

    getDateAttributes(): string[];

    /**
     * An array of eager load options
     */
    eagerLoadAttributes(): EagerLoadOption[];

    /**
     * Eager load all models
     *
     * @param models
     * @param properties
     */
    eagerLoadModels(models: BaseModelInterface[], properties: any): void

    populate(data: any): void;

    [key: string]: any


    /**
     * The Sort order
     */
    sortOrder: number | null;

    /**
     * Get the required attributes before sending something as an ajax request
     */
    getBaseAttributes(): string[];

}