import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
// import moment from 'moment';
import {store} from "@/store/store";
// import {openDialog} from "@/plugins/dialog";
import * as moment from "moment";

const apiClient = axios.create({
    // baseURL: 'https://thaff-thueringen.de/api/v1/',
    // baseURL: 'https://api.myspa.me/api/v1/',
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

apiClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (typeof store !== 'undefined') {
            const userId = store.getters['user/id'] || ':empty:';
            config.headers['x-fair-visitor-id'] = userId;

            const currentFair = store.getters['fairs/current'];
            if(currentFair){
                config.headers['x-fair-id'] = currentFair.id;
            }
        }

        // add dateUpdated to header if it exists
        if (typeof config.data !== "undefined") {
            if (typeof config.data.dateUpdated !== "undefined") {
                const d = config.data.dateUpdated;
                config.headers['If-Unmodified-Since'] = moment.isMoment(d)? d.format() : d;
            }
        }

        return config;
    },
    (error: AxiosError) => Promise.reject(error)
);

let lastVersionCompare: number | null = null;
// Add a response interceptor
apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
        if (typeof response.headers !== 'undefined' && typeof response.headers['x-desk-version'] !== 'undefined') {
            const version = response.headers['x-thaff-version'];
            // const appVersion = '0.0.1';
            const appVersion = process?.env?.VUE_APP_VERSION ?? '0.0.1';
            if (version !== appVersion) {
                const now = Date.now();
                if (lastVersionCompare === null || lastVersionCompare + 5000 < now) {
                    if (confirm('Fenster wird neu geladen wegen Versionsänderung von ' + appVersion + ' auf ' + version)) {
                        location.reload();
                    }

                }
                lastVersionCompare = now;
            }
        }
        return response;
    },
    async (error: any) => {

        const response = error.response ?? null;
        if(response !== null){
            const status = response.status?? null;
            if (status === 412) {
                // return new Promise((resolve, reject) => {
                //     openDialog({
                //         title: 'überschreiben?',
                //         message: 'Der Datensatz wurde bereits von einer anderen Person bearbeitet'
                //     })
                //         .then(response => {
                //             if (response) {
                //                 // yes
                //                 originalRequest.headers['If-Unmodified-Since'] = moment().unix();
                //                 resolve(apiClient.request(originalRequest));
                //             } else {
                //                 reject(error);
                //                 // original = Promise.reject(error);
                //                 // cancel
                //             }
                //         });
                // });
            }
        }

        return Promise.reject(error);
    }
);


export default apiClient;
