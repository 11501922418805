import {Commit, Dispatch} from "vuex";
import {IChatContact} from "@/types";
import Users from "@/api/endpoints/Users";

const setChatContact = ({commit}: { commit: Commit }, payload: IChatContact) => {
    commit('SET_CHAT_CONTACT', payload);
}

const load = async ({commit}: { commit: Commit }, chatContactId: number) => {
    const chatContact = await Users.getChatContact(chatContactId)
    commit('SET_CHAT_CONTACT', chatContact.data);
}

const loadIfExist = async ({dispatch, state}: { dispatch: Dispatch, state: IChatContactState }, chatContactId: number) => {
    if (state.chatContacts.has(chatContactId)) {
        dispatch('load', chatContactId)
    }
}

const mutations = {
    SET_CHAT_CONTACT(state: IChatContactState, payload: IChatContact) {
        state.chatContacts.set(payload.id, payload);
    },
};

interface IChatContactState {
    chatContacts: Map<number, IChatContact>
}

const state: IChatContactState = {
    chatContacts: new Map(),
}

export default {
    namespaced: true,
    actions: {
        setChatContact,
        load,
        loadIfExist
    },
    mutations,
    getters: {
        get(state: IChatContactState) {
            return (chatContactId: number) => {
                console.log('TEST: return chatContact', state.chatContacts);
                return state.chatContacts.has(chatContactId) ? state.chatContacts.get(chatContactId) : null;
            }
        },
    },
    state,
};
