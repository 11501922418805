// @ts-ignore
import {getColumn} from "@/services/ArrayHelper";
import {BaseModelInterface} from "@/models/BaseModelInterface";

export interface StateInterface {
    models: BaseModelInterface[];

    hash?: string
}

/**
 * Check if a model is in the store
 *
 * @param state
 * @param getters
 * @return {function(*): boolean}
 */
export const isInStore = (state: StateInterface, getters: any) => (uid: string): boolean => {
    const model = getters.all.find((model: BaseModelInterface) => model.getUid() === uid) || null;
    return model !== null;
};

/**
 * Get models in the store by models fetched via ajax request
 *
 * @param response
 * @param getters
 * @return {BaseModel[]}
 */
export const getModelsInStore = (response: BaseModelInterface[], getters: any): BaseModelInterface[] => {
    const modelsFromStore: BaseModelInterface[] = [];
    response.forEach(el => {
        if (el && el.id) {
            const element = getters['one'](el.id);
            if (element) {
                modelsFromStore.push(element);
            }
        }
    });
    return modelsFromStore;
};

/**
 * Get a single model
 *
 * @param models
 * @param id
 * @param attribute
 * @return {null}
 */
export const getOne = (models: BaseModelInterface[], id: number | string, attribute: string = 'id'): BaseModelInterface | null => {
    const model = models.find(model => model[attribute] === id);
    return typeof model !== 'undefined' ? model : null
};

type oneFunction = (id: number) => BaseModelInterface | null;
type oneFunctionSlug = (slug: string) => BaseModelInterface | null;
type oneFunctionUid = (uid: string) => BaseModelInterface | null;

type allNotInStoreFunction = (data: BaseModelInterface[]) => number[];

interface DefaultGetterInterface {
    /**
     * Get a single Model
     *
     * @param state
     */
    one(state: StateInterface): oneFunction;

    /**
     * Get a single Model by slug
     *
     * @param state
     */
    oneBySlug(state: StateInterface): oneFunctionSlug;

    /**
     * Get a single Model by craft cms uid
     *
     * @param state
     */
    oneByUid(state: StateInterface): oneFunctionUid;

    /**
     * Get all Models
     *
     * @param state
     */
    all(state: StateInterface): BaseModelInterface[];

    /**
     * Get all models sorted by "sortOrder" param
     *
     * @param state
     */
    allSorted(state: StateInterface): BaseModelInterface[];

    /**
     * Returns an array of ids that are not in the store
     *
     * @param state
     * @param getters
     */
    notInStore(state: StateInterface, getters: any): allNotInStoreFunction;

    /**
     * Check if a model is in the store
     *
     * @param state
     * @param getters
     */
    isInStore(state: StateInterface, getters: any): (uid: string) => boolean;

    /**
     * Get all Models in the store from cloned models without reference in the store
     *
     * @param state
     * @param getters
     */
    getModelsInStore(state: StateInterface, getters: any): (models: BaseModelInterface[]) => BaseModelInterface[];


    /**
     * Get all models in the store by their ID, keep the sort order of the ids
     *
     * @param state
     * @param getters
     */
    allByIds(state: StateInterface, getters: any): (ids: number[]) => BaseModelInterface[];
}

/**
 * The default getters for the stores
 *
 * @type {function(): {all(*): User[], getModelsInStore: (function(): function(*, *): []), one: (function(*): function(*=): *), isInStore: *}}
 */
export const defaultGetters = (): DefaultGetterInterface => ({
    /**
     * Get a single Model by ID
     *
     * @param state
     * @return {function(*=): *}
     */
    one: (state: StateInterface) => (id: number): BaseModelInterface | null => {
        return getOne(state.models, id, 'id');
    },
    oneBySlug: (state: StateInterface) => (slug: string): BaseModelInterface | null => {
        return getOne(state.models, slug, 'slug');
    },
    oneByUid: (state: StateInterface) => (uid: string): BaseModelInterface | null => {
        return getOne(state.models, uid, 'uid');
    },
    /**
     * Get all Models
     *
     * @param state
     * @return {User[]}
     */
    all(state: StateInterface): BaseModelInterface[] {
        return state.models;
    },
    /**
     * Get all sorted by "sortOrder" property
     * @param state
     */
    allSorted(state: StateInterface): BaseModelInterface[] {
        const array = state.models.slice(0, state.models.length);
        array.sort((a, b) => {
            if (a.sortOrder !== null && b.sortOrder !== null && a.sortOrder > b.sortOrder) {
                return 1;
            } else {
                return -1;
            }
        });

        return array;
    },
    /**
     * Returns an array of IDS, that are not in the store
     *
     * @param state
     * @param getters
     * @return {function(*): []}
     */
    notInStore: (state: StateInterface, getters: any) => (data: BaseModelInterface[]): number[] => {
        let all = getters.all;
        const ids = getColumn(all, 'id');

        let notInStore: number[] = [];
        data.forEach(el => {
            if (el.id !== null && ids.includes(el) === false) {
                notInStore.push(el.id);
            }
        });

        return notInStore;
    },
    /**
     * Check if the Model is in the Store
     */
    isInStore: isInStore,
    /**
     * Convert an array of models into models in the store
     *
     * @return {function(*, *): []}
     */
    getModelsInStore: (state: StateInterface, getters: any) => (models: BaseModelInterface[]): BaseModelInterface[] => getModelsInStore(models, getters),
    /**
     *
     * @param state
     * @param getters
     * @return {function(*): *}
     */
    allByIds: (state: StateInterface, getters: any) => (ids: number[]): BaseModelInterface[] => {

        const order: { [key: string]: number } = {};

        ids.forEach((a: number, i: number) => {
            order[a] = i;
        });
        const data = getters.all.filter((object: BaseModelInterface) => object.id && ids.includes(object.id));
        data.sort((a: BaseModelInterface, b: BaseModelInterface) => {
            if (a.id === null) {
                return -1;
            }

            if (b.id === null) {
                return 0;
            }

            return order[a.id] - order[b.id];
        });

        return data;
    }
});
