import apiClient from "@/api/client";

const endpoint: string = 'users';
export default {
    getIdentity() {
        return apiClient.get(`${endpoint}/get-fair-identity`);
    },
    getChatContact(id: number) {
        return apiClient.get(`${endpoint}/chat-contact/${id}`);
    }
}
