import {BaseModel} from "@/models/BaseModel";
import {compareObjects} from "@/services/ModelHelper";
import {hasOwnProperty} from "@/services/Utils";
import {Commit} from "vuex";
import CacheManager from "@/services/CacheManager";
import {AxiosError, AxiosPromise, AxiosResponse} from "axios";
import {BaseModelInterface, ClassType, ModelCreateInterface} from "@/models/BaseModelInterface";

/**
 * Adds a pagination for an array
 *
 * @param array
 * @param headers
 */
export const addPagination = (array: any, headers: any) => {
    const key = 'x-pagination-';
    let pagination: any = {};
    for (let i in headers) {
        if (hasOwnProperty(headers, i) === true && i.includes(key, 0)) {
            let property = i.replace(key, '');
            pagination[property] = parseInt(headers[i]);
        }
    }

    Object.defineProperty(array, "pagination", {
        enumerable: false,
        configurable: false,
        writable: false,
        value: pagination
    });
};

/**
 *
 * @param Model
 * @param data
 * @return {BaseModel|BaseModelInterface}
 */
export const createNewModel = (Model: BaseModelInterface | ModelCreateInterface | ClassType, data: any): BaseModelInterface | null => {
    if (typeof Model === 'function') {
        return new Model(data);
    }

    if (typeof Model === 'object' && typeof Model.create === 'function') {
        return Model.create(data);
    }

    return null;
};

/**
 * Eager load all relations for this model
 *
 * @param {Object[]}    models
 * @param {BaseModel|{create: function, eagerLoadAttributes: function, eagerLoadModels: function}}   modelClass
 */
export const eagerLoadModels = (models: BaseModel[], modelClass: BaseModelInterface | ModelCreateInterface | ClassType) => {
    if (modelClass && models.length) {
        // @ts-ignore
        if (typeof modelClass.create === 'function') {
            // minor hack for disorders ¯\_(ツ)_/¯
            // @ts-ignore
            modelClass = modelClass.create(models[0]);
        }

        // grab all eager loading attributes for this model
        let eagerLoadAttributes = modelClass instanceof BaseModel ? modelClass.eagerLoadAttributes() : [];
        if (Array.isArray(eagerLoadAttributes)) {

            // grab all eager loaded attributes
            // this will be an object like
            // {
            //      attribute: {
            //          id: model,
            //          id: model
            //      }
            // }
            //
            //  eg:
            //
            // {
            //      user: {
            //          1:      {},
            //          419:    {}
            //      }
            // }
            let eagerLoadingModels: { [name: string]: BaseModel[] } = {};
            let eagerLoadIndex: any = {};

            // prepare all of them
            eagerLoadAttributes.forEach(eagerLoadAttribute => {
                let attribute = typeof eagerLoadAttribute === 'object' ? eagerLoadAttribute.attribute : eagerLoadAttribute;
                eagerLoadingModels[attribute] = [];
                eagerLoadIndex[attribute] = {};
            });

            // loop all models and grab eager loaded properties
            for (let i = 0, max = models.length; i < max; i++) {
                let model: BaseModel = models[i];
                eagerLoadAttributes.forEach(eagerLoadAttribute => {
                    let attribute = typeof eagerLoadAttribute === 'object' ? eagerLoadAttribute.attribute : eagerLoadAttribute;

                    if (typeof model[attribute] !== 'undefined') {
                        let values = model[attribute];
                        if (values && Array.isArray(values) === false) {
                            let el = values;
                            if (typeof el.id !== 'undefined') {
                                if (typeof eagerLoadIndex[attribute][el.id] === 'undefined') {
                                    eagerLoadIndex[attribute][el.id] = true;
                                    eagerLoadingModels[attribute].push(el);
                                }
                            }
                        }

                        if (values && Array.isArray(values)) {
                            values.forEach(el => {
                                if (typeof el.id !== 'undefined') {
                                    if (typeof eagerLoadIndex[attribute][el.id] === 'undefined') {
                                        eagerLoadIndex[attribute][el.id] = true;
                                        eagerLoadingModels[attribute].push(el);
                                    }
                                }
                            })
                        }
                        // delete the property so they won't be inserted to the store again
                        if (typeof eagerLoadAttribute !== 'object' || typeof eagerLoadAttribute.delete === 'undefined' || eagerLoadAttribute.delete !== false) {
                            if (typeof eagerLoadAttribute.idAttribute !== 'undefined') {
                                let ids: number[] = [];
                                models[i][attribute].forEach((el: BaseModel) => {
                                    if (el.id) {
                                        ids.push(el.id);
                                    }
                                });

                                models[i][eagerLoadAttribute.idAttribute] = ids;
                            }

                            delete models[i][attribute];
                        }
                    }
                });
            }
            // set them into the store
            // @ts-ignore
            models = modelClass.eagerLoadModels(models, eagerLoadingModels);
        }
    }

    return models;
};

/**
 * Fetch all Models
 *
 * @param commit
 * @param getters
 * @param params
 * @param model
 * @param {CacheManager} cacheManager
 * @return {Promise<unknown>}
 */
export const fetchAll = ({
                             commit,
                             getters,
                             params = {},
                             Model,
                             Api,
                             cacheManager = null
                         }: { commit: Commit, getters: any, params: any, Model: BaseModelInterface | ModelCreateInterface | ClassType, Api: any, cacheManager: null | CacheManager }): Promise<BaseModelInterface | null> => {
    if (typeof Api === 'undefined' || typeof Api.all !== 'function') {
        throw new Error('No valid API provided');
    }

    let hash = null;

    let force = false;
    if (params !== null && typeof params.force !== 'undefined') {
        delete params.force;
        force = true;
    }

    if (cacheManager !== null && force === false) {
        hash = cacheManager.getHash(params);
        let cache = cacheManager.getCached(params);
        if (cache !== null) {
            return cache.promise;
        }
    }

    // let randomNumber = Math.random();
    // if(Model){
    //     console.time(Model._entity + randomNumber);
    // }

    const returnPromise: Promise<BaseModelInterface | null> = new Promise((resolve, reject) => {
        // console.time('fetch all' + Model._entity);
        Api.all(params)
            .then((response: AxiosResponse) => {
                if (typeof response === 'undefined' || !response.data) {
                    reject('Keine Verbindung zum Server möglich');
                    return false;
                }

                let data = eagerLoadModels(response.data, Model);
                let models: BaseModelInterface[] = [];
                data.forEach(e => {
                    const m = createNewModel(Model, e);
                    if (m) {
                        models.push(m);
                    }
                });

                commit('SET', models);

                const resolved = getters.getModelsInStore(models, getters);
                addPagination(resolved, response.headers);
                resolve(resolved);
            })
            .catch((err: AxiosError) => {
                reject(err);
            })
    });

    if (hash !== null && cacheManager !== null) {
        cacheManager.cache(returnPromise, params, hash);
    }

    return returnPromise;
};

/**
 * Get a value and cache it
 *
 * @param hash
 * @param cacheManager
 * @param apiCall
 * @param commit
 * @param mutation
 * @return {Promise<unknown>}
 */
export const getValueAndCache = ({
                                     hash,
                                     cacheManager = null,
                                     apiCall,
                                     commit,
                                     mutation
                                 }: { hash: string, cacheManager: CacheManager | null, apiCall: AxiosPromise, commit: Commit, mutation: string }) => {

    const cached = cacheManager !== null ? cacheManager.getCached(hash) : null;
    if (cached !== null) {
        return cached.promise;
    }
    const promise = new Promise((resolve, reject) => {
        apiCall
            .then((response: AxiosResponse) => {
                const models = response.data;
                commit(mutation, models);
                resolve(models);
            })
            .catch(err => {
                reject(err);
            })
    });

    if (cacheManager !== null) {
        cacheManager.cache(promise, {}, hash);
    }

    return promise;
};

/**
 * Fetch Data via API and store it
 *
 * @param commit
 * @param Model
 * @param getters
 * @param apiCall
 * @return {Promise<unknown>}
 */
export const resolveRequest = ({
                                   commit,
                                   Model,
                                   getters,
                                   apiCall
                               }: { commit: Commit, Model: BaseModelInterface | ModelCreateInterface | ClassType, getters: any, apiCall: AxiosPromise }) => {
    return new Promise((resolve: Function, reject: Function) => {
        apiCall
            .then((response: AxiosResponse) => {
                submitAndResolve({
                    response,
                    resolve,
                    getters,
                    Model,
                    commit,
                    reject,
                })
            })
            .catch(err => {
                reject(err);
            })
    });
};

/**
 *
 * @param {Function} commit
 * @param {Object} getters
 * @param {Number} id
 * @param {Object} params
 * @param {BaseModel} Model
 * @param {{one: CallableFunction}} Api
 * @param {CacheManager} [cacheManager]
 * @param {String} [defaultMutation]
 * @param {Boolean} [force]
 * @param {string} [endpoint]
 */
export const fetchOne = ({
                             commit,
                             getters,
                             id,
                             params = {},
                             force = false,
                             Api,
                             defaultMutation = 'SET',
                             Model,
                             cacheManager = null,
                             endpoint = 'one'
                         }: { commit: Commit, getters: any, id: number|string, params: any, force: boolean, Api: any, defaultMutation: string, Model: BaseModelInterface | ModelCreateInterface | ClassType, cacheManager: CacheManager | null, endpoint?: string }): Promise<BaseModelInterface | null> => {
    if (!id) {
        throw new Error('Please provide an ID for the model');
    }

    if (force === false) {
        const model = getters.one(id);
        if (model !== null) {
            return model;
        }
    }

    let hash = null;
    // check if the promise is cached
    if (cacheManager !== null && force === false) {
        hash = cacheManager.getHash({id: id, params: params});
        const cached = cacheManager.getCached(hash);
        if (cached !== null) {
            return cached.promise;
        }
    }

    const returnPromise: Promise<BaseModel | null> = new Promise((resolve, reject) => {
        Api[endpoint](id, params)
            .then((response: AxiosResponse) => {
                let data = createNewModel(Model, response.data);
                if (data) {
                    commit(defaultMutation, data);

                    resolve(getters.one(data.id));
                } else {
                    resolve(null);
                }
            })
            .catch((err: AxiosError) => {
                reject(err);
            })
    });

    if (hash !== null && cacheManager !== null) {
        cacheManager.cache(returnPromise, params, hash);
    }


    return returnPromise;
};
/**
 * Submit a model and resolve the Promise
 *
 * @param response
 * @param commit
 * @param mutation
 * @param {Function[]}  getters
 * @param {Function}    resolve
 * @param {Function}    reject
 * @param Model
 */
export const submitAndResolve = ({
                                     response,
                                     commit,
                                     mutation = 'SET',
                                     getters,
                                     reject,
                                     resolve,
                                     Model
                                 }: { response: AxiosResponse, commit: Commit, mutation?: string, getters: any, reject: Function, resolve: Function, Model: BaseModelInterface | ModelCreateInterface | ClassType }) => {
    if (typeof response === 'undefined' || typeof response.data === 'undefined') {
        reject('Server konnte nicht erreicht werden');
    }

    let data: BaseModelInterface | null = createNewModel(Model, response.data);
    if (data !== null) {
        commit(mutation, data);

        const newModel = getters['one'](data.id);
        resolve(newModel);
    } else {
        reject('Could not create Model');
    }
};

/**
 * waits for the Promise, stores the data in the store and resolve it with the new model from the store
 *
 * @param {Promise}         promise     The API call
 * @param {Object}          getters     The getters of the store module
 * @param {function}        commit      The Commit function of the store module
 * @param {BaseModel}       Model       The Model that should be populated
 * @return {Promise<unknown>}
 */
export const returnActionPromise = ({
                                        promise,
                                        getters,
                                        commit,
                                        Model
                                    }: { promise: AxiosPromise, getters: any, commit: Commit, Model: BaseModelInterface | ClassType | ModelCreateInterface }) => {
    return new Promise((resolve, reject) => {
        promise
            .then((response: AxiosResponse) => {
                submitAndResolve({
                    response,
                    resolve,
                    getters,
                    Model,
                    commit,
                    reject
                })
            })
            .catch(err => {
                reject(err);
            })
    });
};

/**
 * Delete a model
 *
 * @param commit
 * @param id
 * @param Api
 * @param defaultMutation
 * @return {*}
 */
export const deleteOne = ({
                              commit,
                              id,
                              Api,
                              defaultMutation = 'REMOVE'
                          }: { commit: Commit, id: number | null | BaseModelInterface, Api: any, defaultMutation?: string }) => {
    if (typeof Api.delete !== 'function') {
        throw new Error('The API does not support a "delete" function');
    }

    if (id instanceof BaseModel) {
        id = id.id;
    }

    const promise = Api.delete(id);

    promise
        .then(() => {
            commit(defaultMutation, id)
        })
        .catch((err: AxiosError) => {
            // fail silently REMOVE (-_-) zzz
            console.log(err);
        });

    return promise;
};

/**
 * Set Models in the store
 *
 * @param context
 * @param mutation
 * @param data
 * @return {Promise<unknown>}
 */
export const setModels = ({getters, commit}: { commit: Commit, getters: any }, mutation: string, data: any) => {
    let isArray = true;
    let id: null | number = null;
    if (Array.isArray(data) === false) {
        id = data.id;
        data = [data];
        isArray = false;
    }

    commit(mutation, data);
    return new Promise((resolve) => {
        let modelsInStore = null;
        if (isArray === true) {
            modelsInStore = getters['getModelsInStore'](data, getters);
        } else {
            modelsInStore = getters['one'](id);
        }
        resolve(modelsInStore);
    })
};

/**
 * The default save function
 *
 * @param {{save: function}} Api
 * @param {String} defaultMutation
 * @param {BaseModel} model
 * @param {BaseModel} Model
 * @param {Function} commit
 * @param {Object} getters
 * @return {Promise<unknown>}
 */
export const saveOne = ({
                            Api,
                            defaultMutation,
                            model,
                            Model,
                            commit,
                            getters
                        }: { Api: any, defaultMutation: string, model: BaseModelInterface, Model: BaseModelInterface | ClassType | ModelCreateInterface, commit: Commit, getters: any }) => {
    if (typeof Api.save !== 'function') {
        throw new Error('The API does not provide a "save" function');
    }

    const originalModel = getters['one'](model.id) || null;
    const delta:  BaseModelInterface | boolean | { [key: string]: any }  = compareObjects(model, originalModel);
    const m = delta !== false? delta : model;

    return new Promise((resolve, reject) => {
        Api.save(m)
            .then((response: AxiosResponse) => {
                submitAndResolve({
                    resolve,
                    response,
                    Model,
                    getters,
                    mutation: defaultMutation,
                    commit,
                    reject
                });
            })
            .catch((err: AxiosError) => {
                reject(err);
            })
    })
};

/**
 * List of defaultActions for each store
 *
 * @param Api
 * @param defaultMutation
 * @param cacheManager
 * @param Model
 * @param defaultDeleteMutation
 * @return {{all({commit: *, getters: *}, *): *, one({commit: *, getters: *}, {id: *, params: *}): *, delete({commit: *}, *): *}}
 */
export const defaultActions = ({
                                   Api,
                                   defaultMutation = 'SET',
                                   cacheManager = null,
                                   Model,
                                   defaultDeleteMutation = 'REMOVE'
                               }: { Api: any, defaultMutation?: string, cacheManager: null | CacheManager, Model: BaseModelInterface | ClassType | ModelCreateInterface, defaultDeleteMutation?: string }): { [key: string]: Function } => ({
    setAction({commit, getters}: { commit: Commit, getters: any }, payload: any) {
        return setModels({commit, getters}, 'SET', payload);
    },
    oneAction({commit, getters}: { commit: Commit, getters: any }, {
        id,
        params,
        force = false
    }: { id: number, params: any, force?: boolean }) {
        return fetchOne({
            commit,
            getters,
            force,
            id,
            params,
            Api,
            defaultMutation,
            Model,
            cacheManager
        })
    },
    oneBySlugAction({commit, getters}: { commit: Commit, getters: any }, {
        slug,
        params,
        force = false
    }: { slug: string, params: any, force?: boolean }) {
        return fetchOne({
            commit,
            getters,
            force,
            id: slug,
            params,
            Api,
            defaultMutation,
            Model,
            cacheManager,
            endpoint: 'slug'
        })
    },
    allAction({commit, getters}: { commit: Commit, getters: any }, params: any) {
        return fetchAll({
            commit,
            getters,
            params,
            Model,
            Api,
            cacheManager
        })
    },
    deleteAction({commit}: { commit: Commit }, id: number | null | BaseModelInterface) {
        return deleteOne({
            commit,
            id,
            Api,
            defaultMutation: defaultDeleteMutation
        });
    },
    removeAction({commit}: { commit: Commit }, id: number) {
        commit('REMOVE', id);
    },
    clearAction({commit}: { commit: Commit }) {
        commit('CLEAR');
        if (cacheManager !== null) {
            cacheManager.clear();
        } else {
            console.log('has no cacheManager', this);
        }
    },
    saveAction({commit, getters}: { commit: Commit, getters: any }, model: BaseModelInterface) {
        return saveOne({
            Model,
            Api,
            defaultMutation,
            model,
            commit,
            getters
        })
    }
});