interface anyObject {
    [key: string]: any
}

/**
 * Get the value of an object|array by path
 * this can be a simple string "index" or a nested path like "index.0.index"
 *
 * @param object
 * @param path
 */
export const getValue = (object: anyObject[] | anyObject, path: string | string[]): any => {
    let a: string[] = [];

    if (Array.isArray(path)) {
        a = path;
    } else {
        let s = path.replace(/\[(\w+)\]/g, '.$1');      // convert indexes to properties
        s = s.replace(/^\./, '');                       // strip a leading dot
        a = s.split('.');                                             // split on dot to have an array
    }

    for (let i = 0, n = a.length; i < n; ++i) {
        let k: string = a[i];
        if (typeof object === 'object' && k in object) {
            if (Array.isArray(object)) {
                object = object[parseInt(k)];
            } else {
                object = object[k];
            }
        } else {
            return;
        }
    }

    return object;
}