import FairCategories from "@/store/modules/FairCategories";
import FairProfiles from "@/store/modules/FairProfiles";
import Fairs from "@/store/modules/Fairs";
import Programs from "@/store/modules/Programs";
import User from "@/store/modules/User";
import Socket from "@/store/modules/Socket";
import ChatContact from "@/store/modules/ChatContact";
import JobCategories from "@/store/modules/JobCategories";
import {createStore, Store} from 'vuex'
import {InjectionKey} from 'vue'

const modules: any = {
    'fairs': Fairs,
    'user': User,
    'socket': Socket,
    'chat-contact': ChatContact,
    'fair-profiles': FairProfiles,
    'fair-categories': FairCategories,
    'job-categories': JobCategories,
    'programs': Programs,
};

export interface State {
    isSystemLive: boolean
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    strict: false,
    state: () => ({
        isSystemLive: true,
    }),
    modules,
    mutations: {
        /**
         * The status if the endpoint is live or not
         *
         * @param state
         * @param status
         * @constructor
         */
        SET_LIVE_STATUS(state, status) {
            state.isSystemLive = status;
        },
    },
    getters: {
        /**
         * Returns if the System is Live or if there are any maintenance issues
         *
         * @param state
         * @return {getters.isSystemLive|(function(*))|boolean|*}
         */
        isSystemLive(state) {
            return state.isSystemLive;
        },
    },
    actions: {
        refresh({dispatch}) {
            for (let module in modules) {
                const action = module + '/clear';
                // @ts-ignore
                if (this._actions[action]) {
                    dispatch(action, null, {root: true});
                }
            }
        },
        /**
         * Changes the live status of the server
         * if this is set to "false" chances are high you are redirected to "/offline" route
         * unless you have the permissions
         *
         * @param state
         * @param payload
         */
        setSystemStatus(state, payload) {
            state.commit('SET_LIVE_STATUS', payload);
        },
    }
})
