import {Link} from "@/interfaces/Link";
import {BaseModel} from "@/models/BaseModel";
import moment from "moment";

export default class JobCategory extends BaseModel {

    /**
     * The name of the store Module
     *
     * @type {null|string}
     * @private
     */
    static _entity = 'job-categories';


    constructor(...args: any[]) {
        super(...args);
        this.init(...args);
    }

    init(...args: any[]) {
        super.init(...args);
    }
}