<template>
    <div ref="loadingContainer">
        <router-view />
    </div>
</template>

<script lang="ts">
import FetchDataComposition, {Action} from "@/compositions/FetchDataComposition";
import LoadingComposition from "@/compositions/LoadingComposition";
import {defineComponent, onMounted, ref} from 'vue'
import 'toastr/toastr.less';
import '../src/theme/less/style.less';
import {useRoute} from "vue-router";

export default defineComponent({
    name: 'App',
    // setup() {
    //
    //     const loadingContainer = ref(null);
    //     const {
    //         startLoading,
    //         endLoading,
    //         isLoading
    //     } = LoadingComposition(loadingContainer)
    //
    //     const {mounted, unMounted} = FetchDataComposition({
    //         actions: [
    //             new Action({
    //                 type: 'user/get-identity'
    //             })
    //         ],
    //         onLoadingStart: startLoading,
    //         onLoadingEnd: endLoading
    //     })
    //
    //     return {
    //         startLoading,
    //         endLoading,
    //         isLoading,
    //         // fetch Data composition
    //         mounted, unMounted,
    //     }
    // },
})
</script>
