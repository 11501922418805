const USER_ID_KEY: string = 'socket-user-ids';

/**
 * Grab the user ID for a fair
 *
 * @param fairId
 */
export const getUserId = (fairId: number): string | null => {

    const ids = localStorage.getItem(USER_ID_KEY);
    if(!ids){
        return null;
    }

    const parsed = JSON.parse(ids);

    return parsed[fairId]?? null;
}

/**
 * Set the user ID for a given Fair
 *
 * @param id
 * @param fairId
 */
export const setUserId = (id: string, fairId: number) => {
    const existing = localStorage.getItem(USER_ID_KEY);
    let ids = existing? JSON.parse(existing) : {};

    ids[fairId] = id;
    localStorage.setItem(USER_ID_KEY, JSON.stringify(ids));
}

/**
 * generates a random user id
 */
export const generateUserId = (): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 128; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
