import Users from "@/api/endpoints/Users";
import {UserInterface} from "@/models/UserInterface";
import {getUserId, setUserId} from "@/services/Authmanager";
import CacheManager from "@/services/CacheManager";
import {AxiosError} from "axios";
import {Commit} from "vuex";
import {joinRooms} from '@/api/socket/Client';

let cacheManager = new CacheManager();
const getIdentityAction = ({
                               commit, rootGetters
                           }: { commit: Commit, rootGetters: any }) => {
    const isCached = cacheManager.getCached('identity');

    if (isCached) {
        return isCached.promise;
    }

    const promise = new Promise((resolve, reject) => {
        Users.getIdentity()
            .then((response) => {
                commit('SET_IDENTITY', response.data.identity);
                commit('SET_ID', response.data.id);
                commit('SET_JWT', response.data.encoded);
                setUserId(response.data.id, rootGetters['fairs/current'].id);
                resolve(response.data.identity);
            })
            .catch((err: AxiosError) => {
                reject(err);
            })
    })


    cacheManager.cache(promise, {}, 'identity', 1);

    return promise;
}

const setUsername = ({commit}: { commit: Commit }, username: string) => {
    commit('SET_USERNAME', username);
}

const mutations = {
    SET_IDENTITY(state: UserState, payload: UserInterface) {
        state.identity = payload;
    },
    SET_JWT(state: UserState, payload: string) {
        state.jwt = payload;
    },
    SET_ID(state: UserState, payload: string) {
        state.id = payload;
    },
    SET_USERNAME(state: UserState, username: string) {
        state.username = username;
    },
    SOCKET_INITIALIZED(state: UserState, value: boolean = true){
        state.socketInitialized = value;
    }
};

interface UserState {
    identity: UserInterface | null,

    username: string
    id: string | null;
    jwt: string,
    socketInitialized: boolean
}

const state = {
    id: null,
    username: '',
    identity: null,
    jwt: '',
    socketInitialized: false,
}

export default {
    namespaced: true,
    actions: {
        'get-identity': getIdentityAction,
        setUsername,
        ['join-rooms']({getters, commit}: {getters: any, commit: Commit}, {token}: {token: string }){
            joinRooms(token);
            commit('SOCKET_INITIALIZED', true);
        }
    },
    mutations,
    getters: {
        ['get-identity'](state: UserState): UserInterface | null {
            return state.identity
        },
        getUsername(state: UserState): string {
            return state.username
        },
        id(state: UserState, a: any, b: any, rootGetters: any) {
            let id = state.id;
            if (state.id === null && rootGetters['fairs/current']) {
                const fair = rootGetters['fairs/current'];
                id = getUserId(fair.id);
            }

            return id;
        },
        ['socket-initialized'](state: UserState){
              return state.socketInitialized;
        },
        jwt(state: UserState) {
            return state.jwt;
        }
    },
    state,
};
