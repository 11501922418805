import {BaseModel} from "@/models/BaseModel";
import {BaseModelInterface} from "@/models/BaseModelInterface";
import {AxiosError} from "axios";
import toastr from 'toastr'

/**
 * Show a message
 *
 * @param message
 */
export const showMessage = (message: string) => {
    toastr.success(message, '', {
        // your options
        closeButton: true,
        timeOut: 2000,
        positionClass: 'toast-top-center',
    })
}

(window as any).showMessage = showMessage;

/**
 * Show an error
 *
 * @param {Object}          err
 * @param {BaseModel|null}  model
 */
export const showError = (err: AxiosError<{ [key: string]: string[] } | string> | string, model: BaseModelInterface | null = null) => {
    const message = getMessageFromError(err);
    if (message) {
        toastr.error(message, '', {
            // your options
            closeButton: false,
            timeOut: 5000,
            positionClass: 'toast-top-center',
        })
    }

    if (model !== null && typeof err === 'object') {
        const errors = err?.response?.data ?? null;
        if (errors !== null && typeof errors === 'object') {
            model.setErrors(errors)
        }
    }
};
(window as any).showError = showError;

/**
 * Get the message from an error
 *
 * @param error
 * @return {string|null|string|string|*}
 */
const getMessageFromError = (error: AxiosError<{ [key: string]: string | string[] } | string> | string): string | null => {
    if (typeof error === 'string') {
        return error;
    }

    let data = error?.response?.data ?? null;
    if (data !== null) {
        if (typeof data === 'string') {
            return data;
        }

        const errors = data.error ?? data.errors ?? data.message ?? null;
        if (errors !== null) {
            return getFirstString(errors);
        }

        if (typeof data === 'object') {
            return getFirstString(data);
        }
    }

    if (typeof error === 'object' && Object.keys(error).length === 0) {
        // it's probably an exception...
        return error.toString();
    }

    if (typeof error.message !== 'undefined') {
        return error.message;
    }

    return '';
};

/**
 * Get the first string in an array or object
 *
 * @param object
 */
const getFirstString = (object: string | string[] | { [key: string]: string | string[] }): string | null => {
    if (typeof object === 'string') {
        return object;
    }

    if (Array.isArray(object)) {
        return object[0] ?? null;
    }

    if (typeof object === 'object') {
        const keys = Object.keys(object);
        if (keys.length) {
            const e = object[keys[0]];
            return Array.isArray(e) ? e[0] : e;
        }
    }

    return null;
}

export const getRandomString = (length: number): string => {
    let result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

/**
 * Check if an object has a certain property
 *
 * @param object
 * @param property
 * @return {boolean}
 */
export const hasOwnProperty = (object: any, property: string) => {
    return Object.prototype.hasOwnProperty.call(object, property);
}

/**
 * Check if the user agent is mobile
 */
export const isMobile = (): boolean => {
    let mobile = false; //initiate as false
// device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) {
        mobile = true;
    }

    return mobile;
}


export const isDev = () => {
    return process.env.NODE_ENV === 'development'
}
